@import 'bootstrap/scss/forms/form-check';

$check-height-sm: 1rem;
$check-height-md: 1.125rem;
$check-height-lg: 1.25rem;
$check-height-xl: 1.5rem;

$check-font-size-sm: 0.75rem;
$check-font-size-md: 0.875rem;
$check-font-size-lg: 0.875rem;
$check-font-size-xl: 1rem;

$check-spacing-sm: 0.125rem;
$check-spacing-md: 0.438rem;
$check-spacing-lg: 0.625rem;
$check-spacing-xl: 0.75rem;

$check-label-spacing-sm: 0.5rem;
$check-label-spacing-md: 0.625rem;
$check-label-spacing-lg: 0.75rem;
$check-label-spacing-xl: 1rem;

$states: (
  default: $secondary,
  primary-alt: $primary-alt,
  valid: $form-feedback-valid-color,
  invalid: $form-feedback-invalid-color,
  edited: $form-feedback-edited-color,
  warning: $form-feedback-warning-color,
);

$sizes: 'sm', 'md', 'lg', 'xl';

.form-check:not(.form-switch) {
  position: relative;
  display: inline-flex;
  flex-wrap: nowrap;
  align-items: center;
  padding: 0;

  &:not(.form-check-inline, .form-check-reverse) {
    display: flex;
  }

  .form-check-label {
    cursor: pointer;
    padding: $check-spacing-md $check-spacing-md $check-spacing-md
      ($check-spacing-md + $check-height-md + $check-label-spacing-md);
    line-height: $check-height-md;
    font-size: $check-font-size-md;
    z-index: 0;
    color: $primary !important;

    &:empty {
      padding-left: $check-spacing-md !important;

      &::before {
        content: '';
        display: block;
        height: $check-height-md;
        width: $check-height-md;
      }
    }

    &:active {
      background-color: rgba($primary, 14%);
    }
  }

  .form-check-input {
    cursor: pointer;
    position: absolute;
    border: none;
    background-color: transparent !important;
    top: 0;
    bottom: 0;
    margin: auto 0;
    border-radius: 0;
    z-index: 1;
    background-position: center;

    height: $check-height-md !important;
    width: $check-height-md !important;
    left: $check-spacing-md;

    &:focus,
    &:focus-visible {
      outline: none !important;
      box-shadow: none !important;
    }

    &:focus-visible {
      & + .form-check-label {
        background-color: rgba($primary, 6%);
      }
    }

    &:focus-visible:checked {
      & + .form-check-label {
        outline: 2px solid $primary;
      }
    }

    &[type='radio'] {
      border-radius: 50%;

      & + .form-check-label {
        border-radius: 9999px;
      }
    }
  }

  // SIZING
  &.form-check-sm {
    .form-check-label {
      font-size: $check-font-size-sm !important;
      padding: $check-spacing-sm $check-spacing-sm $check-spacing-sm
        ($check-spacing-sm + $check-height-sm + $check-label-spacing-sm);
      line-height: $check-height-sm;

      &:empty {
        padding-left: $check-spacing-sm !important;

        &::before {
          height: $check-height-sm;
          width: $check-height-sm;
        }
      }
    }

    .form-check-input {
      height: $check-height-sm !important;
      width: $check-height-sm !important;
      left: $check-spacing-sm;
    }
  }

  &.form-check-lg {
    .form-check-label {
      font-size: $check-font-size-lg;
      line-height: $check-height-lg;
      padding: $check-spacing-lg $check-spacing-lg $check-spacing-lg
        ($check-spacing-lg + $check-height-lg + $check-label-spacing-lg);

      &:empty {
        padding-left: $check-spacing-lg !important;

        &::before {
          height: $check-height-lg;
          width: $check-height-lg;
        }
      }
    }

    .form-check-input {
      height: $check-height-lg !important;
      width: $check-height-lg !important;
      left: $check-spacing-lg;
    }
  }

  &.form-check-xl {
    .form-check-label {
      font-size: $check-font-size-xl !important;
      line-height: $check-height-xl;
      padding: $check-spacing-xl $check-spacing-xl $check-spacing-xl
        ($check-spacing-xl + $check-height-xl + $check-label-spacing-xl);

      &:empty {
        padding-left: $check-spacing-xl !important;

        &::before {
          height: $check-height-xl;
          width: $check-height-xl;
        }
      }
    }

    .form-check-input {
      height: $check-height-xl !important;
      width: $check-height-xl !important;
      left: $check-spacing-xl;
    }
  }
}

// REVERSE
.form-check-reverse {
  text-align: initial;
  .form-check-input {
    right: $check-spacing-md;
    left: unset !important;
  }

  .form-check-label {
    padding: $check-spacing-md ($check-spacing-md + $check-height-md + $check-label-spacing-md) $check-spacing-md
      $check-spacing-md !important;
  }

  &.form-check-sm {
    .form-check-label {
      padding: $check-spacing-sm ($check-spacing-sm + $check-height-sm + $check-label-spacing-sm) $check-spacing-sm
        $check-spacing-sm !important;
    }

    .form-check-input {
      right: $check-spacing-sm;
    }
  }

  &.form-check-lg {
    .form-check-label {
      padding: $check-spacing-lg ($check-spacing-lg + $check-height-lg + $check-label-spacing-lg) $check-spacing-lg
        $check-spacing-lg !important;
    }

    .form-check-input {
      right: $check-spacing-lg;
    }
  }

  &.form-check-xl {
    .form-check-label {
      padding: $check-spacing-xl ($check-spacing-xl + $check-height-xl + $check-label-spacing-xl) $check-spacing-xl
        $check-spacing-xl !important;
    }

    .form-check-input {
      right: $check-spacing-xl;
    }
  }
}

// STATES
@mixin checkbox-state($color) {
  &:hover {
    .form-check-label,
    ~ .form-check-label {
      background-color: rgba($color, 6%);
    }
  }

  .form-check-input {
    &:active:not(.disabled):not([disabled]) + .form-check-label {
      background-color: rgba($color, 14%) !important;
    }

    &:focus-visible {
      & + .form-check-label {
        outline: 2px solid $color;
        background-color: rgba($color, 6%) !important;
      }
    }

    &:focus-visible:checked {
      & + .form-check-label {
        outline: 2px solid $color;
      }
    }

    &:active:not(.disabled):not([disabled]) + .form-check-label {
      background-color: rgba($color, 14%) !important;
    }
  }
}

@mixin checkbox-size($color, $colorState, $size: 'md') {
  @if ($size == 'md') {
    background-image: escape-svg(
      url("data:image/svg+xml,<svg width='18' height='18' viewBox='0 0 18 18' fill='none' xmlns='http://www.w3.org/2000/svg'><path fill-rule='evenodd' clip-rule='evenodd' d='M16 2H2V16H16V2ZM0 0V18H18V0H0Z' fill='#{$color}'/></svg>")
    );

    &:hover {
      background-image: escape-svg(
        url("data:image/svg+xml,<svg width='18' height='18' viewBox='0 0 18 18' fill='none' xmlns='http://www.w3.org/2000/svg'><path fill-rule='evenodd' clip-rule='evenodd' d='M16 2H2V16H16V2ZM0 0V18H18V0H0Z' fill='#{$colorState}'/></svg>")
      );
    }

    &:checked {
      background-image: escape-svg(
        url("data:image/svg+xml,<svg width='18' height='18' viewBox='0 0 18 18' fill='none' xmlns='http://www.w3.org/2000/svg'><path fill-rule='evenodd' clip-rule='evenodd' d='M18 0H0V18H18V0ZM2.25 9.99991L3.31066 8.93925L6.49269 12.1213L14.4476 4.16638L15.5082 5.22704L6.49263 14.2427L2.25 9.99991Z' fill='#{$colorState}'/></svg>")
      );
    }

    &:indeterminate {
      background-image: escape-svg(
        url("data:image/svg+xml,<svg width='18' height='18' viewBox='0 0 18 18' fill='none' xmlns='http://www.w3.org/2000/svg'><path fill-rule='evenodd' d='M18 0H0V18H18V0ZM14 8.25H4V9.75H14V8.25Z' fill='#{$colorState}'/></svg>")
      );
    }
  }

  @if ($size == 'sm') {
    background-image: escape-svg(
      url("data:image/svg+xml,<svg width='16' height='16' viewBox='0 0 16 16' fill='none' xmlns='http://www.w3.org/2000/svg'><path fill-rule='evenodd' clip-rule='evenodd' d='M14.25 1.75H1.75V14.25H14.25V1.75ZM0 0V16H16V0H0Z' fill='#{$color}'/></svg>")
    );

    &:hover {
      background-image: escape-svg(
        url("data:image/svg+xml,<svg width='16' height='16' viewBox='0 0 16 16' fill='none' xmlns='http://www.w3.org/2000/svg'><path fill-rule='evenodd' clip-rule='evenodd' d='M14.25 1.75H1.75V14.25H14.25V1.75ZM0 0V16H16V0H0Z' fill='#{$colorState}'/></svg>")
      );
    }

    &:checked {
      background-image: escape-svg(
        url("data:image/svg+xml,<svg width='16' height='16' viewBox='0 0 16 16' fill='none' xmlns='http://www.w3.org/2000/svg'><path fill-rule='evenodd' clip-rule='evenodd' d='M16 0H0V16H16V0ZM2.00012 8.68532L2.94293 7.74251L5.77141 10.571L12.8424 3.5L13.7852 4.44281L5.77133 12.4567L2.00012 8.68532Z' fill='#{$colorState}'/></svg>")
      );
    }

    &:indeterminate {
      background-image: escape-svg(
        url("data:image/svg+xml,<svg width='16' height='16' viewBox='0 0 16 16' fill='none' xmlns='http://www.w3.org/2000/svg'><path fill-rule='evenodd' d='M16 0H0V16H16V0ZM12.5 7.25H3.5V8.75H12.5V7.25Z' fill='#{$colorState}'/></svg>")
      );
    }
  }

  @if ($size == 'lg') {
    background-image: escape-svg(
      url("data:image/svg+xml,<svg width='20' height='20' viewBox='0 0 20 20' fill='none' xmlns='http://www.w3.org/2000/svg'><path fill-rule='evenodd' clip-rule='evenodd' d='M17.75 2.25H2.25V17.75H17.75V2.25ZM0 0V20H20V0H0Z' fill='#{$color}'/></svg>")
    );

    &:hover {
      background-image: escape-svg(
        url("data:image/svg+xml,<svg width='20' height='20' viewBox='0 0 20 20' fill='none' xmlns='http://www.w3.org/2000/svg'><path fill-rule='evenodd' clip-rule='evenodd' d='M17.75 2.25H2.25V17.75H17.75V2.25ZM0 0V20H20V0H0Z' fill='#{$colorState}'/></svg>")
      );
    }

    &:checked {
      background-image: escape-svg(
        url("data:image/svg+xml,<svg width='20' height='20' viewBox='0 0 20 20' fill='none' xmlns='http://www.w3.org/2000/svg'><path fill-rule='evenodd' clip-rule='evenodd' d='M20 0H0V20H20V0ZM2.5 11L3.67851 9.82146L7.21405 13.357L16.0529 4.51819L17.2314 5.6967L7.21402 15.714L2.5 11Z' fill='#{$colorState}'/></svg>")
      );
    }

    &:indeterminate {
      background-image: escape-svg(
        url("data:image/svg+xml,<svg width='20' height='20' viewBox='0 0 20 20' fill='none' xmlns='http://www.w3.org/2000/svg'><path fill-rule='evenodd' d='M20 0H0V20H20V0ZM16 9.125H4V10.875H16V9.125Z' fill='#{$colorState}'/></svg>")
      );
    }
  }

  @if ($size == 'xl') {
    background-image: escape-svg(
      url("data:image/svg+xml,<svg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'><path fill-rule='evenodd' clip-rule='evenodd' d='M21.5 2.5H2.5V21.5H21.5V2.5ZM0 0V24H24V0H0Z' fill='#{$color}'/></svg>")
    );

    &:hover {
      background-image: escape-svg(
        url("data:image/svg+xml,<svg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'><path fill-rule='evenodd' clip-rule='evenodd' d='M21.5 2.5H2.5V21.5H21.5V2.5ZM0 0V24H24V0H0Z' fill='#{$colorState}'/></svg>")
      );
    }

    &:checked {
      background-image: escape-svg(
        url("data:image/svg+xml,<svg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'><path fill-rule='evenodd' clip-rule='evenodd' d='M24 0H0V24H24V0ZM3 13L4.41421 11.5858L8.65685 15.8284L19.2635 5.2218L20.6777 6.63602L8.65685 18.6568L3 13Z' fill='#{$colorState}'/></svg>")
      );
    }

    &:indeterminate {
      background-image: escape-svg(
        url("data:image/svg+xml,<svg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'><path fill-rule='evenodd' d='M24 0H0V24H24V0ZM19 11H5V13H19V11Z' fill='#{$colorState}'/></svg>")
      );
    }
  }
}

@mixin radio-size($color, $colorState, $size: 'md') {
  @if ($size == 'md') {
    background-image: escape-svg(
      url("data:image/svg+xml,<svg width='18' height='18' viewBox='0 0 18 18' fill='none' xmlns='http://www.w3.org/2000/svg'><path fill-rule='evenodd' clip-rule='evenodd' d='M2 9C2 12.866 5.13401 16 9 16C12.866 16 16 12.866 16 9C16 5.13401 12.866 2 9 2C5.13401 2 2 5.13401 2 9ZM9 0C4.02944 0 0 4.02944 0 9C0 13.9706 4.02944 18 9 18C13.9706 18 18 13.9706 18 9C18 4.02944 13.9706 0 9 0Z' fill='#{$color}'/></svg>")
    );

    &:hover {
      background-image: escape-svg(
        url("data:image/svg+xml,<svg width='18' height='18' viewBox='0 0 18 18' fill='none' xmlns='http://www.w3.org/2000/svg'><path fill-rule='evenodd' clip-rule='evenodd' d='M2 9C2 12.866 5.13401 16 9 16C12.866 16 16 12.866 16 9C16 5.13401 12.866 2 9 2C5.13401 2 2 5.13401 2 9ZM9 0C4.02944 0 0 4.02944 0 9C0 13.9706 4.02944 18 9 18C13.9706 18 18 13.9706 18 9C18 4.02944 13.9706 0 9 0Z' fill='#{$colorState}'/></svg>")
      );
    }

    &:checked {
      background-image: escape-svg(
        url("data:image/svg+xml,<svg width='18' height='18' viewBox='0 0 18 18' fill='none' xmlns='http://www.w3.org/2000/svg'><path fill-rule='evenodd' clip-rule='evenodd' d='M2 9C2 12.866 5.13401 16 9 16C12.866 16 16 12.866 16 9C16 5.13401 12.866 2 9 2C5.13401 2 2 5.13401 2 9ZM9 0C4.02944 0 0 4.02944 0 9C0 13.9706 4.02944 18 9 18C13.9706 18 18 13.9706 18 9C18 4.02944 13.9706 0 9 0Z' fill='#{$colorState}'/><path d='M14 9C14 11.7614 11.7614 14 9 14C6.23858 14 4 11.7614 4 9C4 6.23858 6.23858 4 9 4C11.7614 4 14 6.23858 14 9Z' fill='#{$colorState}'/></svg>")
      );
    }
  }

  @if ($size == 'sm') {
    background-image: escape-svg(
      url("data:image/svg+xml,<svg width='16' height='16' viewBox='0 0 16 16' fill='none' xmlns='http://www.w3.org/2000/svg'><path fill-rule='evenodd' clip-rule='evenodd' d='M1.75 8C1.75 11.4518 4.54822 14.25 8 14.25C11.4518 14.25 14.25 11.4518 14.25 8C14.25 4.54822 11.4518 1.75 8 1.75C4.54822 1.75 1.75 4.54822 1.75 8ZM8 0C3.58172 0 0 3.58172 0 8C0 12.4183 3.58172 16 8 16C12.4183 16 16 12.4183 16 8C16 3.58172 12.4183 0 8 0Z' fill='#{$color}'/></svg>")
    );

    &:hover {
      background-image: escape-svg(
        url("data:image/svg+xml,<svg width='16' height='16' viewBox='0 0 16 16' fill='none' xmlns='http://www.w3.org/2000/svg'><path fill-rule='evenodd' clip-rule='evenodd' d='M1.75 8C1.75 11.4518 4.54822 14.25 8 14.25C11.4518 14.25 14.25 11.4518 14.25 8C14.25 4.54822 11.4518 1.75 8 1.75C4.54822 1.75 1.75 4.54822 1.75 8ZM8 0C3.58172 0 0 3.58172 0 8C0 12.4183 3.58172 16 8 16C12.4183 16 16 12.4183 16 8C16 3.58172 12.4183 0 8 0Z' fill='#{$colorState}'/></svg>")
      );
    }

    &:checked {
      background-image: escape-svg(
        url("data:image/svg+xml,<svg width='16' height='16' viewBox='0 0 16 16' fill='none' xmlns='http://www.w3.org/2000/svg'><path fill-rule='evenodd' clip-rule='evenodd' d='M1.75 8C1.75 11.4518 4.54822 14.25 8 14.25C11.4518 14.25 14.25 11.4518 14.25 8C14.25 4.54822 11.4518 1.75 8 1.75C4.54822 1.75 1.75 4.54822 1.75 8ZM8 0C3.58172 0 0 3.58172 0 8C0 12.4183 3.58172 16 8 16C12.4183 16 16 12.4183 16 8C16 3.58172 12.4183 0 8 0Z' fill='#{$colorState}'/><path d='M12.25 8C12.25 10.3472 10.3472 12.25 8 12.25C5.65279 12.25 3.75 10.3472 3.75 8C3.75 5.65279 5.65279 3.75 8 3.75C10.3472 3.75 12.25 5.65279 12.25 8Z' fill='#{$colorState}'/></svg>")
      );
    }
  }

  @if ($size == 'lg') {
    background-image: escape-svg(
      url("data:image/svg+xml,<svg width='20' height='20' viewBox='0 0 20 20' fill='none' xmlns='http://www.w3.org/2000/svg'><path fill-rule='evenodd' clip-rule='evenodd' d='M2.25 10C2.25 14.2802 5.71979 17.75 10 17.75C14.2802 17.75 17.75 14.2802 17.75 10C17.75 5.71979 14.2802 2.25 10 2.25C5.71979 2.25 2.25 5.71979 2.25 10ZM10 0C4.47715 0 0 4.47715 0 10C0 15.5228 4.47715 20 10 20C15.5228 20 20 15.5228 20 10C20 4.47715 15.5228 0 10 0Z' fill='#{$color}'/></svg>")
    );

    &:hover {
      background-image: escape-svg(
        url("data:image/svg+xml,<svg width='20' height='20' viewBox='0 0 20 20' fill='none' xmlns='http://www.w3.org/2000/svg'><path fill-rule='evenodd' clip-rule='evenodd' d='M2.25 10C2.25 14.2802 5.71979 17.75 10 17.75C14.2802 17.75 17.75 14.2802 17.75 10C17.75 5.71979 14.2802 2.25 10 2.25C5.71979 2.25 2.25 5.71979 2.25 10ZM10 0C4.47715 0 0 4.47715 0 10C0 15.5228 4.47715 20 10 20C15.5228 20 20 15.5228 20 10C20 4.47715 15.5228 0 10 0Z' fill='#{$colorState}'/></svg>")
      );
    }

    &:checked {
      background-image: escape-svg(
        url("data:image/svg+xml,<svg width='20' height='20' viewBox='0 0 20 20' fill='none' xmlns='http://www.w3.org/2000/svg'><path fill-rule='evenodd' clip-rule='evenodd' d='M2.25 10C2.25 14.2802 5.71979 17.75 10 17.75C14.2802 17.75 17.75 14.2802 17.75 10C17.75 5.71979 14.2802 2.25 10 2.25C5.71979 2.25 2.25 5.71979 2.25 10ZM10 0C4.47715 0 0 4.47715 0 10C0 15.5228 4.47715 20 10 20C15.5228 20 20 15.5228 20 10C20 4.47715 15.5228 0 10 0Z' fill='#{$colorState}'/><path d='M15.5 10C15.5 13.0376 13.0376 15.5 10 15.5C6.96243 15.5 4.5 13.0376 4.5 10C4.5 6.96243 6.96243 4.5 10 4.5C13.0376 4.5 15.5 6.96243 15.5 10Z' fill='#{$colorState}'/></svg>")
      );
    }
  }

  @if ($size == 'xl') {
    background-image: escape-svg(
      url("data:image/svg+xml,<svg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'><path fill-rule='evenodd' clip-rule='evenodd' d='M2.5 12C2.5 17.2467 6.75329 21.5 12 21.5C17.2467 21.5 21.5 17.2467 21.5 12C21.5 6.75329 17.2467 2.5 12 2.5C6.75329 2.5 2.5 6.75329 2.5 12ZM12 0C5.37258 0 0 5.37258 0 12C0 18.6274 5.37258 24 12 24C18.6274 24 24 18.6274 24 12C24 5.37258 18.6274 0 12 0Z' fill='#{$color}'/></svg>")
    );

    &:hover {
      background-image: escape-svg(
        url("data:image/svg+xml,<svg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'><path fill-rule='evenodd' clip-rule='evenodd' d='M2.5 12C2.5 17.2467 6.75329 21.5 12 21.5C17.2467 21.5 21.5 17.2467 21.5 12C21.5 6.75329 17.2467 2.5 12 2.5C6.75329 2.5 2.5 6.75329 2.5 12ZM12 0C5.37258 0 0 5.37258 0 12C0 18.6274 5.37258 24 12 24C18.6274 24 24 18.6274 24 12C24 5.37258 18.6274 0 12 0Z' fill='#{$colorState}'/></svg>")
      );
    }

    &:checked {
      background-image: escape-svg(
        url("data:image/svg+xml,<svg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'><path fill-rule='evenodd' clip-rule='evenodd' d='M2.5 12C2.5 17.2467 6.75329 21.5 12 21.5C17.2467 21.5 21.5 17.2467 21.5 12C21.5 6.75329 17.2467 2.5 12 2.5C6.75329 2.5 2.5 6.75329 2.5 12ZM12 0C5.37258 0 0 5.37258 0 12C0 18.6274 5.37258 24 12 24C18.6274 24 24 18.6274 24 12C24 5.37258 18.6274 0 12 0Z' fill='#{$colorState}'/><path d='M19 12C19 15.866 15.866 19 12 19C8.13401 19 5 15.866 5 12C5 8.13401 8.13401 5 12 5C15.866 5 19 8.13401 19 12Z' fill='#{$colorState}'/></svg> ")
      );
    }
  }
}

// Custom SVG backgrounds for checkboxes
@each $state, $color in $states {
  $wrapper-class: '.form-check-#{$state}';
  $color-state: #{$color};

  @if ($state == 'default') {
    $wrapper-class: '.form-check';
    $color-state: #{$primary};
  }

  #{$wrapper-class}:not(.form-switch) {
    $color-state: #{$color};

    @if ($state == 'default') {
      $color-state: #{$primary};
    }

    @if ($state == 'primary-alt') {
      .form-check-label {
        color: $color-state !important;
      }
    }

    @include checkbox-state($color);

    .form-check-input[type='checkbox'] {
      @include checkbox-size($color, $color-state);
    }

    .form-check-input[type='checkbox'][disabled],
    .form-check-input[type='checkbox']:disabled {
      @include checkbox-size($color, $color);
    }

    .form-check-input[type='radio'] {
      @include radio-size($color, $color-state);
    }

    .form-check-input[type='radio'][disabled],
    .form-check-input[type='radio']:disabled {
      @include radio-size($color, $color);
    }

    @each $size in $sizes {
      &.form-check-#{$size} {
        .form-check-input[type='checkbox'] {
          @include checkbox-size($color, $color-state, $size);
        }

        .form-check-input[type='checkbox'][disabled],
        .form-check-input[type='checkbox']:disabled {
          @include checkbox-size($color, $color, $size);
        }
      }
    }

    @each $size in $sizes {
      &.form-check-#{$size} {
        .form-check-input[type='radio'] {
          @include radio-size($color, $color-state, $size);
        }

        .form-check-input[type='radio'][disabled],
        .form-check-input[type='radio']:disabled {
          @include radio-size($color, $color, $size);
        }
      }
    }
  }
}

// VALIDATION
// Checkbox
@mixin checkbox-validation($state, $size: 'md') {
  $color: map-get($states, $state);
  @include checkbox-size($color, $color, $size);
  @include checkbox-state(map-get($states, $state));
}

.was-validated {
  .form-check:not(.form-switch):not(.form-check-inline):valid,
  .form-check-input[type='checkbox']:valid {
    @include checkbox-validation('valid');
  }

  @each $size in $sizes {
    .form-check-#{$size} {
      &.form-check:not(.form-switch):not(.form-check-inline):valid,
      .form-check-input[type='checkbox']:valid {
        @include checkbox-validation('valid', $size);
      }
    }
  }

  .form-check:not(.form-switch):not(.form-check-inline):invalid,
  .form-check-input[type='checkbox']:invalid {
    @include checkbox-validation('invalid');
  }

  @each $size in $sizes {
    .form-check-#{$size} {
      &.form-check:not(.form-switch):not(.form-check-inline):invalid,
      .form-check-input[type='checkbox']:invalid {
        @include checkbox-validation('invalid', $size);
      }
    }
  }
}

.form-check > .form-check:not(.form-switch):not(.form-check-inline).is-valid,
.form-check > .form-check-input[type='checkbox'].is-valid {
  @include checkbox-validation('valid');
}

.form-check:not(.form-switch) {
  @each $size in $sizes {
    &.form-check-#{$size} .form-check:not(.form-switch):not(.form-check-inline).is-valid,
    &.form-check-#{$size} > .form-check-input[type='checkbox'].is-valid {
      @include checkbox-validation('valid', $size);
    }
  }
}

.form-check > .form-check:not(.form-switch):not(.form-check-inline).is-invalid,
.form-check > .form-check-input[type='checkbox'].is-invalid {
  @include checkbox-validation('invalid');
}

.form-check {
  @each $size in $sizes {
    &.form-check-#{$size} .form-check:not(.form-switch):not(.form-check-inline).is-invalid,
    &.form-check-#{$size} > .form-check-input[type='checkbox'].is-invalid {
      @include checkbox-validation('invalid', $size);
    }
  }
}

.form-check > .form-check:not(.form-switch):not(.form-check-inline).is-warning,
.form-check > .form-check-input[type='checkbox'].is-warning {
  @include checkbox-validation('warning');
}

.form-check {
  @each $size in $sizes {
    &.form-check-#{$size} .form-check:not(.form-switch):not(.form-check-inline).is-warning,
    &.form-check-#{$size} > .form-check-input[type='checkbox'].is-warning {
      @include checkbox-validation('warning', $size);
    }
  }
}

.form-check > .form-check:not(.form-switch):not(.form-check-inline).is-edited,
.form-check > .form-check-input[type='checkbox'].is-edited {
  @include checkbox-validation('edited');
}

.form-check {
  @each $size in $sizes {
    &.form-check-#{$size} .form-check:not(.form-switch):not(.form-check-inline).is-edited,
    &.form-check-#{$size} > .form-check-input[type='checkbox'].is-edited {
      @include checkbox-validation('edited', $size);
    }
  }
}

// Radio
@mixin radio-validation($state, $size: 'md') {
  $color: map-get($states, $state);
  @include radio-size($color, $color, $size);
  @include checkbox-state(map-get($states, $state));
}

.was-validated {
  .form-check:not(.form-switch):not(.form-check-inline):valid,
  .form-check-input[type='radio']:valid {
    @include radio-validation('valid');
  }

  @each $size in $sizes {
    .form-check-#{$size} {
      &.form-check:not(.form-switch):not(.form-check-inline):valid,
      .form-check-input[type='radio']:valid {
        @include radio-validation('valid', $size);
      }
    }
  }

  .form-check:not(.form-switch):not(.form-check-inline):invalid,
  .form-check-input[type='radio']:invalid {
    @include radio-validation('invalid');
  }

  @each $size in $sizes {
    .form-check-#{$size} {
      &.form-check:not(.form-switch):not(.form-check-inline):invalid,
      .form-check-input[type='radio']:invalid {
        @include radio-validation('invalid', $size);
      }
    }
  }
}

.form-check > .form-check:not(.form-switch):not(.form-check-inline).is-valid,
.form-check > .form-check-input[type='radio'].is-valid {
  @include radio-validation('valid');
}

.form-check {
  @each $size in $sizes {
    &.form-check-#{$size} .form-check:not(.form-switch):not(.form-check-inline).is-valid,
    &.form-check-#{$size} > .form-check-input[type='radio'].is-valid {
      @include radio-validation('valid', $size);
    }
  }
}

.form-check > .form-check:not(.form-switch):not(.form-check-inline).is-invalid,
.form-check > .form-check-input[type='radio'].is-invalid {
  @include radio-validation('invalid');
}

.form-check {
  @each $size in $sizes {
    &.form-check-#{$size} .form-check:not(.form-switch):not(.form-check-inline).is-invalid,
    &.form-check-#{$size} > .form-check-input[type='radio'].is-invalid {
      @include radio-validation('invalid', $size);
    }
  }
}

.form-check > .form-check:not(.form-switch):not(.form-check-inline).is-warning,
.form-check > .form-check-input[type='radio'].is-warning {
  @include radio-validation('warning');
}

.form-check {
  @each $size in $sizes {
    &.form-check-#{$size} .form-check:not(.form-switch):not(.form-check-inline).is-warning,
    &.form-check-#{$size} > .form-check-input[type='radio'].is-warning {
      @include radio-validation('warning', $size);
    }
  }
}

.form-check > .form-check:not(.form-switch):not(.form-check-inline).is-edited,
.form-check > .form-check-input[type='radio'].is-edited {
  @include radio-validation('edited');
}

.form-check {
  @each $size in $sizes {
    &.form-check-#{$size} .form-check:not(.form-switch):not(.form-check-inline).is-edited,
    &.form-check-#{$size} > .form-check-input[type='radio'].is-edited {
      @include radio-validation('edited', $size);
    }
  }
}

.form-check-input[disabled],
.form-check-input:disabled {
  ~ .form-check-label {
    cursor: default !important;
    background-color: transparent !important;
  }
}

//
// FORM SWITCH
//
@mixin switch-input-checked($shadowSize: 4px, $size: 'md') {
  @each $state, $color in $states {
    $wrapper-class: '.form-check-#{$state}';
    $color-state: $color;

    @if ($state == 'default') {
      $wrapper-class: '.form-check';
      $color-state: $primary;
    }

    &#{$wrapper-class} {
      .form-check-input:checked {
        @if ($size == 'sm') {
          --#{$prefix}form-switch-bg: #{escape-svg(
              url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' width='10' height='10' viewBox='0 0 10 10' fill='none'><circle cx='5' cy='5' r='5' fill='#{$bg-lvl1}'/></svg>")
            )};
        } @else {
          --#{$prefix}form-switch-bg: #{escape-svg(
              url("data:image/svg+xml,<svg viewBox='0 0 20 20' xmlns='http://www.w3.org/2000/svg'><circle cx='10' cy='10' r='10' fill='#{$bg-lvl1}'/><circle cx=' 10' cy=' 10' r=' 6' fill='#{$color-state}'/><circle cx=' 10' cy=' 10' r=' 4' fill='#{$bg-lvl1}'/></svg>")
            )};
        }

        background-color: $color-state;
        border-color: $color-state;

        &:hover,
        &:focus {
          box-shadow: 0 0 0 $shadowSize rgba($color-state, 0.06);
        }

        &:active {
          box-shadow: 0 0 0 $shadowSize rgba($color-state, 0.14);
        }
      }
    }
  }
}

.form-switch {
  display: flex;
  align-items: flex-start;
  padding-left: 0;

  .form-check-input {
    --#{$prefix}form-switch-bg: #{escape-svg(
        url("data:image/svg+xml,<svg viewBox='0 0 20 20' xmlns='http://www.w3.org/2000/svg'><circle cx='10' cy='10' r='10' fill='#{$secondary}'/><rect x=' 5' y=' 9' height=' 2' width=' 10' fill='#{$primary-alt}'></rect></svg>")
      )};
    --#{$prefix}form-check-bg: transparent;
    background-origin: content-box;
    border-color: $border-color;
    transition: background-position 0.08s ease-out;

    width: 40px;
    padding: 2px;
    height: 24px;
    border-width: 2px;
    margin: 4px;

    &:hover,
    &:focus {
      --#{$prefix}form-switch-bg: #{escape-svg(
          url("data:image/svg+xml,<svg viewBox='0 0 20 20' xmlns='http://www.w3.org/2000/svg'><circle cx='10' cy='10' r='10' fill='#{$primary}'/><rect x=' 5' y=' 9' height=' 2' width=' 10' fill='#{$primary-alt}'></rect></svg>")
        )};
      border-color: $secondary;
      --#{$prefix}form-check-bg: #{rgba($primary, 0.06)};
      box-shadow: 0 0 0 4px rgba($primary, 0.06);
    }

    &:focus-visible {
      outline: 2px solid $primary;
      outline-offset: 1px;
    }

    &:active {
      --#{$prefix}form-check-bg: #{rgba($primary, 0.14)};
      box-shadow: 0 0 0 4px rgba($primary, 0.14);
      filter: none;
    }
  }

  @include switch-input-checked();

  // SIZING
  &.form-check-lg {
    .form-check-input {
      width: 52px;
      padding: 3px;
      height: 28px;
      margin: 6px;

      &:hover,
      &:focus {
        box-shadow: 0 0 0 6px rgba($primary, 0.06);
      }

      &:active {
        box-shadow: 0 0 0 6px rgba($primary, 0.14);
      }
    }

    @include switch-input-checked(6px);
  }

  &.form-check-xl {
    .form-check-input {
      width: 58px;
      padding: 4px;
      height: 32px;
      margin: 8px;

      &:hover,
      &:focus {
        box-shadow: 0 0 0 8px rgba($primary, 0.06);
      }

      &:active {
        box-shadow: 0 0 0 8px rgba($primary, 0.14);
      }
    }

    @include switch-input-checked(8px);
  }

  &.form-check-sm {
    .form-check-input {
      --#{$prefix}form-switch-bg: #{escape-svg(
          url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' width='10' height='10' viewBox='0 0 10 10' fill='none'><circle cx='5' cy='5' r='5' fill='#{$secondary}'/></svg>")
        )};
      width: 28px;
      padding: 1px;
      height: 16px;
      margin: 2px;

      &:hover,
      &:focus {
        --#{$prefix}form-switch-bg: #{escape-svg(
            url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' width='10' height='10' viewBox='0 0 10 10' fill='none'><circle cx='5' cy='5' r='5' fill='#{$primary}'/></svg>")
          )};
        box-shadow: 0 0 0 2px rgba($primary, 0.06);
      }

      &:active {
        box-shadow: 0 0 0 2px rgba($primary, 0.14);
      }
    }

    @include switch-input-checked(2px, 'sm');
  }
}
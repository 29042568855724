@import 'bootstrap/scss/button-group';

@mixin iconCheckbox($color-name: $secondary) {
  background-image: escape-svg(
    url("data:image/svg+xml,<svg viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'><path fill-rule='evenodd' clip-rule='evenodd' d='M19 5H5V19H19V5ZM3 3V21H21V3H3Z' fill='#{$color-name}'/></svg>")
  );
}

@mixin iconCheckboxChecked($color-name: $primary) {
  background-image: escape-svg(
    url("data:image/svg+xml,<svg viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'><path d='M21 3H3V21H21V3ZM5.25 12.9999L6.31066 11.9393L9.49269 15.1213L17.4476 7.16638L18.5082 8.22704L9.49263 17.2427L5.25 12.9999Z' fill='#{$color-name}'/></svg>")
  ) !important;
}

@mixin iconRadio($color-name: $secondary) {
  background-image: escape-svg(
    url("data:image/svg+xml,<svg viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'><path fill-rule='evenodd' clip-rule='evenodd' d='M5 12C5 15.866 8.13401 19 12 19C15.866 19 19 15.866 19 12C19 8.13401 15.866 5 12 5C8.13401 5 5 8.13401 5 12ZM12 3C7.02944 3 3 7.02944 3 12C3 16.9706 7.02944 21 12 21C16.9706 21 21 16.9706 21 12C21 7.02944 16.9706 3 12 3Z' fill='#{$color-name}'/></svg>")
  );
}

@mixin iconRadioChecked($color-name: $primary) {
  background-image: escape-svg(
    url("data:image/svg+xml,<svg viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'><path fill-rule='evenodd' clip-rule='evenodd' d='M5 12C5 15.866 8.13401 19 12 19C15.866 19 19 15.866 19 12C19 8.13401 15.866 5 12 5C8.13401 5 5 8.13401 5 12ZM12 3C7.02944 3 3 7.02944 3 12C3 16.9706 7.02944 21 12 21C16.9706 21 21 16.9706 21 12C21 7.02944 16.9706 3 12 3Z' fill='#{$color-name}'/><path d='M17 12C17 14.7614 14.7614 17 12 17C9.23858 17 7 14.7614 7 12C7 9.23858 9.23858 7 12 7C14.7614 7 17 9.23858 17 12Z' fill='#{$color-name}'/></svg>")
  ) !important;
}

.btn-group-toggle {
  .btn {
    box-shadow: inset 0 0 0 1px $border-color;
    --#{$prefix}btn-disabled-color: #{$primary} !important;
    --#{$prefix}border-width: 1px !important;

    > .toggle-icon::before {
      content: '';
      display: inline-block;
      width: $btn-icon-size;
      height: $btn-icon-size;
      vertical-align: middle;
    }

    &:focus {
      box-shadow: inset 0 0 0 1px $border-color !important;
    }
    &:hover {
      --#{$prefix}btn-bg: #{get-interaction-color('hover', $primary)} !important;
    }
    &.active,
    &.active:focus {
      box-shadow: inset 0 0 0 1px $primary !important;
    }
  }

  input[type='checkbox'] ~ .btn {
    > .toggle-icon::before {
      @include iconCheckbox();
    }
    &:focus,
    &:hover,
    &.active,
    &.active:focus {
      > .toggle-icon::before {
        @include iconCheckbox($primary);
      }
    }
  }

  input[type='radio'] ~ .btn {
    > .toggle-icon::before {
      @include iconRadio();
    }
    &:focus,
    &:hover,
    &.active,
    &.active:focus {
      > .toggle-icon::before {
        @include iconRadio($primary);
      }
    }
  }
}

.btn-group-toggle > input[type='checkbox']:checked + .btn {
  z-index: 2;
  > .toggle-icon {
    &::before {
      @include iconCheckboxChecked();
    }
  }
}

.btn-group-toggle > input[type='radio']:checked + .btn {
  z-index: 2;
  > .toggle-icon {
    &::before {
      @include iconRadioChecked();
    }
  }
}

.btn-group > input[type='checkbox'] + .btn:not(:last-child) {
  margin-right: 0.25rem;
}

.btn-group > :not(.btn-check:first-child) + .btn {
  margin-left: calc(var(--#{$prefix}border-width) * -1);
}

// XL SIZE
.btn-group-xl .btn {
  min-height: $input-height-xl;

  @include button-size($btn-padding-y-xl, $btn-padding-x-xl, $btn-font-size-xl, $btn-border-radius-xl);

  @include btn-icon-text-sizing($btn-icon-size-xl, $btn-gap-xl, $btn-padding-y-xl);

  > .toggle-icon::before {
    width: $btn-icon-size-xl;
    height: $btn-icon-size-xl;
  }
}

// LG SIZE
.btn-group-lg .btn {
  > .toggle-icon::before {
    width: $btn-icon-size-lg;
    height: $btn-icon-size-lg;
  }
}

// SM SIZE
// LG SIZE
.btn-group-sm .btn {
  > .toggle-icon::before {
    width: $btn-icon-size-sm;
    height: $btn-icon-size-sm;
  }
}

.btn-group,
.btn-group-vertical {
  > [class^='btn-toggle'] {
    --#{$prefix}btn-border-color: #{$btn-default-border-color} !important;
    --#{$prefix}btn-color: #{$secondary} !important;
    --#{$prefix}btn-bg: transparent !important;
    --#{$prefix}btn-hover-bg: #{get-interaction-color('hover', $primary)} !important;
    --#{$prefix}btn-hover-color: #{$primary} !important;
    --#{$prefix}btn-hover-border-color: #{$primary} !important;
    --#{$prefix}btn-active-bg: #{get-interaction-color('selected', $primary)} !important;

    &:not(:disabled):not(.disabled) {
      cursor: pointer;
    }
  }

  @each $color, $value in $theme-colors {
    .btn-check:focus + .btn-toggle-#{$color}:not([disabled]):not(.disabled) {
      box-shadow: inset 0 0 0 1px #{$value} !important;
    }

    .btn-check:focus-visible + .btn-toggle-#{$color}:not([disabled]):not(.disabled) {
      box-shadow: inset 0 0 0 0.125rem #{$value} !important;
      --#{$prefix}btn-bg: #{get-interaction-color('hover', $value)} !important;
    }

    input[type='checkbox'] ~ .btn-toggle-#{$color}:not([disabled]):not(.disabled) {
      &:focus,
      &:hover,
      &.active,
      &.active:focus {
        > .toggle-icon::before {
          @include iconCheckbox($value);
        }
      }
    }

    input[type='checkbox']:focus-visible + .btn-toggle-#{$color}:not([disabled]):not(.disabled) {
      --#{$prefix}btn-color: #{$value} !important;
      > .toggle-icon::before {
        @include iconCheckbox($value);
      }
    }

    input[type='radio'] ~ .btn-toggle-#{$color}:not([disabled]):not(.disabled) {
      &:focus,
      &:hover,
      &.active,
      &.active:focus {
        > .toggle-icon::before {
          @include iconRadio($value);
        }
      }
    }

    input[type='radio']:focus-visible + .btn-toggle-#{$color}:not([disabled]):not(.disabled) {
      --#{$prefix}btn-color: #{$value} !important;
      > .toggle-icon::before {
        @include iconRadio($value);
      }
    }

    input[type='checkbox']:checked + .btn-toggle-#{$color}:not([disabled]):not(.disabled) {
      > .toggle-icon::before {
        @include iconCheckboxChecked($value);
      }
    }

    input[type='radio']:checked + .btn-toggle-#{$color}:not([disabled]):not(.disabled) {
      > .toggle-icon::before {
        @include iconRadioChecked($value);
      }
    }

    .btn-toggle-#{$color}:not([disabled]):not(.disabled) {
      --#{$prefix}btn-color: #{$secondary} !important;
      --#{$prefix}btn-active-color: #{$value} !important;
      --#{$prefix}btn-active-border-color: #{$value} !important;
      --#{$prefix}btn-hover-color: #{$value} !important;
      --#{$prefix}btn-border-color: #{$value} !important;
      --#{$prefix}btn-hover-border-color: #{$value} !important;
      --#{$prefix}btn-hover-bg: #{get-interaction-color('hover', $value)} !important;
      --#{$prefix}btn-active-bg: #{get-interaction-color('selected', $value)} !important;
      --#{$prefix}btn-active-shadow: inset 0 0 0 1px #{$value} !important;

      &:hover {
        --#{$prefix}btn-color: #{$value} !important;
        --#{$prefix}btn-bg: #{get-interaction-color('hover', $value)} !important;
        --#{$prefix}btn-active-bg: #{get-interaction-color('selected_hover', $value)} !important;
      }

      &:focus:not(button),
      &:active:not(button) {
        --#{$prefix}btn-color: #{$value} !important;
        box-shadow: inset 0 0 0 1px #{$value} !important;
        --#{$prefix}btn-bg: #{get-interaction-color('selected', $value)} !important;
        --#{$prefix}btn-active-bg: #{get-interaction-color('selected_onclick', $value)} !important;
        z-index: 2;
      }

      &:focus-visible,
      &:focus-within:not(button) {
        box-shadow: inset 0 0 0 0.125rem #{$value} !important;
        --#{$prefix}btn-bg: #{get-interaction-color('hover', $value)} !important;
      }

      &.active {
        --#{$prefix}btn-color: #{$value} !important;
        box-shadow: inset 0 0 0 1px #{$value} !important;
        --#{$prefix}btn-bg: #{get-interaction-color('selected', $value)} !important;
        --#{$prefix}btn-active-bg: #{get-interaction-color('selected', $value)} !important;
        &:focus {
          box-shadow: inset 0 0 0 1px #{$value} !important;
        }
        &:active {
          box-shadow: inset 0 0 0 1px #{$value} !important;
          --#{$prefix}btn-active-bg: #{get-interaction-color('selected_onclick', $value)} !important;
        }
        &:focus-visible {
          box-shadow: inset 0 0 0 0.125rem #{$value} !important;
        }
      }
    }
  }

  .btn.btn-xl {
    .icon {
      font-size: $btn-icon-size-xl;
      line-height: 1;
    }
  }

  .btn.btn-lg {
    .icon {
      font-size: $btn-icon-size-lg;
      line-height: 1;
    }
  }

  .btn.btn-sm {
    .icon {
      font-size: $btn-icon-size-sm;
      line-height: 1;
    }
  }
}

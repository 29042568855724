@import 'bootstrap/scss/pagination';

$pagination-font-size-sm: $font-size-sm !default;
$pagination-font-size-lg: $btn-font-size-lg !default;
$pagination-border-radius: $border-radius !default;
$pagination-font-size-xl: $btn-font-size-xl !default;
$pagination-padding-y-xl: 0 !default;
$pagination-padding-x-xl: 0 !default;
$pagination-disabled-item-opacity: $btn-disabled-opacity !default;
$page-link-opacity: map-get($opacities, '20') !default;
$page-link-hover-opacity: get-interaction-opacity('hover') !default;

$pagination-item-min-width-sm: 20px !default;
$pagination-item-min-width: 32px !default;
$pagination-item-min-width-lg: 40px !default;
$pagination-item-min-width-xl: 48px !default;

.pagination {
  @include pagination-size(
    $pagination-padding-y,
    $pagination-padding-x,
    $pagination-font-size,
    $pagination-border-radius
  );

  // Disabled item
  .page-item.disabled {
    opacity: $pagination-disabled-item-opacity;

    .page-link {
      background: transparent;
    }
  }

  .page-item.active .page-link {
    &:hover {
      background: rgba($pagination-active-hover-bg, 0.05);
    }
  }
}

.page-link {
  min-width: $pagination-item-min-width;
  height: $pagination-item-min-width;
  display: flex;
  align-items: center;
  justify-content: center;

  &:focus {
    outline: 0;
    box-shadow: none !important;
    border: 1px solid $primary;
  }

  &:active {
    --#{$prefix}btn-active-border-color: #{$pagination-active-border-color};
    --#{$prefix}btn-active-color: #{$pagination-active-color} !important;
    --#{$prefix}btn-active-bg: #{$pagination-active-bg};
  }

  .icon {
    margin: 0 !important;
  }
}

.pagination-borderless {
  .page-item.active .page-link {
    color: $pagination-color;
    border-color: transparent;
    background: rgba($pagination-hover-bg, $page-link-opacity);
  }

  .page-link {
    margin-left: 0;
    color: $pagination-color;
    border-color: transparent;

    &:focus,
    &:hover {
      background: rgba($pagination-hover-bg, $page-link-hover-opacity);
    }

    &:active {
      background: rgba($pagination-hover-bg, $page-link-opacity);
      border: none;
    }
  }

  .page-item.disabled .page-link {
    border-color: transparent;
  }
}

.pagination-outline {
  @include pagelink-active(transparent, $pagination-border-color, $pagination-color);
}

// Alternate styles
@each $color, $value in $theme-colors {
  .pagination-outline-#{$color} {
    @include pagelink-active(transparent, $value, $value);
    .page-link {
      border-color: transparent;
      background: transparent;
    }
  }
  .pagination-#{$color} {
    &:not(.pagination-borderless) .page-item {
      .page-link {
        border-color: $value;
      }
    }
    &.pagination-borderless .page-item {
      &.active {
        .page-link {
          border-color: $value;
        }
      }
      .page-link:hover,
      .page-link:focus,
      .page-link:active {
        border-color: $value;
      }
    }

    @include pagelink-active($value, $value, $primary-alt);
  }
}

//
// Sizing
//

.pagination-sm {
  @include pagination-size(
    $pagination-padding-y-sm,
    $pagination-padding-x-sm,
    $pagination-font-size-sm,
    $pagination-border-radius
  );

  .page-link {
    min-width: $pagination-item-min-width-sm;
    height: $pagination-item-min-width-sm;
  }
}

.pagination-lg {
  @include pagination-size(
    $pagination-padding-y-lg,
    $pagination-padding-x-lg,
    $pagination-font-size-lg,
    $pagination-border-radius
  );

  .page-link {
    min-width: $pagination-item-min-width-lg;
    height: $pagination-item-min-width-lg;
  }
}

.pagination-xl {
  @include pagination-size(
    $pagination-padding-y-xl,
    $pagination-padding-x-xl,
    $pagination-font-size-xl,
    $pagination-border-radius
  );

  .page-link {
    min-width: $pagination-item-min-width-xl;
    height: $pagination-item-min-width-xl;
  }
}

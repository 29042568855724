@import 'bootstrap/scss/badge';

$badge-square-size: px-to-rem(6) !default;
$badge-square-opacity: map-get($opacities, '70') !default;

$badge-padding-y-sm: px-to-rem(3) !default;
$badge-padding-x-sm: px-to-rem(6) !default;
$badge-font-size-sm: $font-size-sm !default;
$badge-square-size-sm: $badge-square-size !default;

$badge-padding-x-md: px-to-rem(7) !default;

$badge-padding-y-lg: px-to-rem(5) !default;
$badge-padding-x-lg: px-to-rem(9) !default;
$badge-font-size-lg: $font-size-base !default;
$badge-square-size-lg: $badge-square-size !default;

$badge-padding-y-xl: px-to-rem(8) !default;
$badge-padding-x-xl: px-to-rem(12) !default;
$badge-font-size-xl: $font-size-lg !default;
$badge-square-size-xl: px-to-rem(8) !default;

// Dismissible
$badge-dismissible-button-size-sm: px-to-rem(18) !default;
$badge-dismissible-icon-size-sm: px-to-rem(14) !default;
$badge-dismissible-button-size-md: px-to-rem(20) !default;
$badge-dismissible-icon-size-md: px-to-rem(16) !default;
$badge-dismissible-button-size-lg: px-to-rem(24) !important;
$badge-dismissible-icon-size-lg: px-to-rem(16) !important;
$badge-dismissible-button-size-xl: px-to-rem(32) !default;
$badge-dismissible-icon-size-xl: px-to-rem(18) !default;
$badge-dismissible-btn-color-sgbs: var(--secondary) !default;

// Prepend square
.badge:not(.badge-dismissible):not(.rounded-pill) {
  position: relative;
  padding-left: $badge-padding-x-md * 2 + $badge-square-size;

  &::before {
    content: '';
    opacity: $badge-square-opacity;
    position: absolute;
    top: 0;
    bottom: 0;
    left: $badge-padding-x-md;
    margin: auto;
    width: $badge-square-size;
    height: $badge-square-size;
    background: currentColor;
  }
}

.badge-sm {
  --#{$prefix}badge-padding-y: #{$badge-padding-y-sm};
  --#{$prefix}badge-padding-x: #{$badge-padding-x-sm};
  --#{$prefix}badge-font-size: #{$badge-font-size-sm};

  font-size: $badge-font-size-sm;

  &:not(.badge-dismissible):not(.rounded-pill) {
    padding-left: $badge-padding-y-sm * 2 + $badge-font-size-sm;

    &::before {
      left: $badge-padding-x-sm;
      width: $badge-square-size-sm;
      height: $badge-square-size-sm;
    }
  }
}

.badge-lg {
  --#{$prefix}badge-padding-y: #{$badge-padding-y-lg};
  --#{$prefix}badge-padding-x: #{$badge-padding-x-lg};
  --#{$prefix}badge-font-size: #{$badge-font-size-lg};

  &:not(.badge-dismissible):not(.rounded-pill) {
    padding-left: $badge-padding-y-lg * 2 + $badge-font-size-lg;

    &::before {
      left: $badge-padding-x-lg;
      width: $badge-square-size-lg;
      height: $badge-square-size-lg;
    }
  }
}

.badge-xl {
  --#{$prefix}badge-padding-y: #{$badge-padding-y-xl};
  --#{$prefix}badge-padding-x: #{$badge-padding-x-xl};
  --#{$prefix}badge-font-size: #{$badge-font-size-xl};

  &:not(.badge-dismissible):not(.rounded-pill) {
    padding-left: $badge-padding-y-xl * 2 + $badge-font-size-xl;

    &::before {
      left: $badge-padding-x-xl;
      width: $badge-square-size-xl;
      height: $badge-square-size-xl;
    }
  }
}

// Colored variants
@mixin generate-palette-badges($colors) {
  @each $name, $color in $colors {
    .badge-#{$name} {
      background-color: $color;
      border-color: $color;

      &.badge-primary-alt {
        --#{$prefix}badge-color: #{$primary};
      }
    }

    .badge-outline-#{$name} {
      --#{$prefix}badge-color: #{$color};
      background: transparent;
      outline: $border-width solid $color;
    }

    .badge-discreet-#{$name} {
      --#{$prefix}badge-color: #{$color} !important;
      background-color: rgba($color, $discreet-btn-gradient) !important;
    }

    .badge-flat-#{$name} {
      --#{$prefix}badge-color: #{$color} !important;
      background-color: transparent !important;
    }
  }
}

.badge-default {
  --#{$prefix}badge-color: #{$primary};
  border: $border-width solid $badge-default-border-color;
  background: transparent;

  &.badge-prepend-square::before {
    opacity: $badge-square-opacity;
  }
}

// DISMISSBIBLE
//

.badge-dismissible:not(.badge-dismissible-right-button) {
  padding-left: 0;
}
.badge-dismissible-right-button {
  padding-right: 0;
}
.badge-dismissible {
  --#{$prefix}badge-padding-y: 0 !important;

  display: inline-flex;
  height: $badge-dismissible-button-size-md;
  line-height: $badge-dismissible-button-size-md;
  align-items: center;
  vertical-align: initial;

  .btn {
    --#{$prefix}btn-active-color: #{currentColor} !important;

    min-width: 0;
    min-height: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    width: $badge-dismissible-button-size-md;
    height: $badge-dismissible-button-size-md;
    .icon {
      font-size: $badge-dismissible-icon-size-md !important;
      width: $badge-dismissible-button-size-md;
      height: $badge-dismissible-button-size-md;
      line-height: $badge-dismissible-button-size-md;
    }
  }
}
.badge-dismissible.badge-sm,
.badge-dismissible.text-small {
  height: $badge-dismissible-button-size-sm;
  line-height: $badge-dismissible-button-size-sm;
  .btn {
    width: $badge-dismissible-button-size-sm;
    height: $badge-dismissible-button-size-sm;
    .icon {
      font-size: $badge-dismissible-icon-size-sm !important;
      width: $badge-dismissible-button-size-sm;
      height: $badge-dismissible-button-size-sm;
      line-height: $badge-dismissible-button-size-sm;
    }
  }
}
.badge-dismissible.badge-lg,
.badge-dismissible.text-large {
  height: $badge-dismissible-button-size-lg;
  line-height: $badge-dismissible-button-size-lg;
  .btn {
    width: $badge-dismissible-button-size-lg;
    height: $badge-dismissible-button-size-lg;
    .icon {
      font-size: $badge-dismissible-icon-size-lg !important;
      width: $badge-dismissible-button-size-lg;
      height: $badge-dismissible-button-size-lg;
      line-height: $badge-dismissible-button-size-lg;
    }
  }
}
.badge-dismissible.badge-xl,
.badge-dismissible.text-xlarge {
  height: $badge-dismissible-button-size-xl;
  line-height: $badge-dismissible-button-size-xl;
  .btn {
    width: $badge-dismissible-button-size-xl;
    height: $badge-dismissible-button-size-xl;
    .icon {
      font-size: $badge-dismissible-icon-size-xl !important;
      width: $badge-dismissible-button-size-xl;
      height: $badge-dismissible-button-size-xl;
      line-height: $badge-dismissible-button-size-xl;
    }
  }
}

.badge.badge-dismissible > button {
  color: inherit;
  border: 0;
  padding: 0;
}

.badge.badge-dismissible.badge-default > button {
  color: $badge-dismissible-btn-color-sgbs;
}

@include generate-palette-badges($theme-colors);
@include generate-palette-badges($secondary-colors);

//
// ADAPTATED COLOR
//

// Default badge adapted xxxx
@each $background in $adaptive-backgrounds {
  @each $name, $color in map-get($adaptive-palette, $background) {
    $adapted_color: map-get(map-get($adaptive-palette, $background), $name);
    .#{$background} {
      .badge.badge-#{$name} {
        background-color: $adapted_color;
        border-color: $adapted_color;
      }
    }
  }
}

// On dark backgrounds
@each $background in $adaptive-backgrounds-dark {
  @each $name, $color in map-get($adaptive-palette, $background) {
    @if ($name != 'dark') {
      .#{$background} .badge.badge-#{$name} {
        color: #000;
      }
    }
  }
}

// On light backgrounds
@each $background in $adaptive-backgrounds-light {
  @each $name, $color in map-get($adaptive-palette, $background) {
    $adapted_color: map-get(map-get($adaptive-palette, $background), $name);
    .#{$background} {
      @if ($name == 'light') {
        .badge.badge-#{$name} {
          color: #000;
        }
      } @else {
        .badge.badge-#{$name} {
          color: #fff;
        }
      }
      @if ($name == 'success') {
        .badge.badge-success {
          $adapted_success: map-get(map-get($adaptive-palette, $background), 'success');
          background-color: button-darken-background($adapted_success, $success-percent-reduction);
          border-color: button-darken-background($adapted_success, $success-percent-reduction);
        }
      }

      @if ($name == 'warning') {
        .badge.badge-warning {
          $adapted_warning: map-get(map-get($adaptive-palette, $background), 'warning');
          background-color: button-darken-background($adapted_warning, $warning-percent-reduction);
          border-color: button-darken-background($adapted_warning, $warning-percent-reduction);
        }
      }
    }
  }
}

// Discreet button adapted
@each $background in $adaptive-backgrounds {
  @each $name, $color in map-get($adaptive-palette, $background) {
    $adapted_color: map-get(map-get($adaptive-palette, $background), $name);
    .#{$background} .badge-discreet-#{$name} {
      --#{$prefix}badge-color: #{$adapted_color} !important;
      background-color: rgba($adapted_color, $discreet-btn-gradient) !important;
    }
  }
}

// Discreet Badge over light backgrounds
@each $background in $adaptive-backgrounds-light {
  .#{$background} {
    .badge-discreet-secondary {
      --#{$prefix}badge-color: #{light-discreet-color($background, 'secondary')} !important;
    }
    .badge-discreet-info {
      --#{$prefix}badge-color: #{light-discreet-color($background, 'info')} !important;
    }
    .badge-discreet-success {
      --#{$prefix}badge-color: #{light-discreet-color($background, 'success')} !important;
    }
    .badge-discreet-warning {
      --#{$prefix}badge-color: #{light-discreet-color($background, 'warning')} !important;
    }
    .badge-discreet-danger {
      --#{$prefix}badge-color: #{light-discreet-color($background, 'danger')} !important;
    }
  }
}

// Discreet badge over dark backgrounds
@each $background in $adaptive-backgrounds-dark {
  .#{$background} {
    .badge-discreet-secondary {
      --#{$prefix}badge-color: #{dark-discreet-color($background, 'secondary')} !important;
    }
    .bagde-discreet-info {
      --#{$prefix}badge-color: #{info-discreet-color-on-dark($background)} !important;
    }
    .badge-discreet-danger {
      --#{$prefix}badge-color: #{discreet-danger-text-color-dark-background($background)} !important;
    }
  }
}

// Outline button adapted
@each $background in $adaptive-backgrounds {
  @each $name, $color in map-get($adaptive-palette, $background) {
    $adapted_color: map-get(map-get($adaptive-palette, $background), $name);
    .#{$background} {
      .badge.badge-outline-#{$name} {
        border-color: $adapted_color;
        color: $adapted_color;
      }
    }
  }
}

// Outline button over Light background
@each $background in $adaptive-backgrounds-light {
  @each $name, $color in map-get($adaptive-palette, $background) {
    $adapted_color: map-get(map-get($adaptive-palette, $background), $name);

    .#{$background} {
      @if ($name == 'success') {
        .badge.badge-outline-success {
          $adapted_success: map-get(map-get($adaptive-palette, $background), 'success');
          color: darken($adapted_success, $success-percent-reduction);
          border-color: button-darken-background($adapted_success, $success-percent-reduction);
        }
      }

      @if ($name == 'warning') {
        .badge.badge-outline-warning {
          $adapted_warning: map-get(map-get($adaptive-palette, $background), 'warning');
          color: darken($adapted_warning, $warning-percent-reduction);
          border-color: button-darken-background($adapted_warning, $warning-percent-reduction);
        }
      }
    }
  }
}

// Outline button over Dark background
@each $background in $adaptive-backgrounds-dark {
  @each $name, $color in map-get($adaptive-palette, $background) {
    $adapted_color: map-get(map-get($adaptive-palette, $background), $name);

    .#{$background} .btn.btn-outline-#{$name} {
      &:hover:not(:disabled):not(.disabled) {
        color: #000;
        background-color: button-lighten-background($adapted_color);
        border-color: button-lighten-background($adapted_color);
      }

      &:focus,
      &.focus {
        background: $adapted_color;
        color: #000;
      }
    }
  }
}

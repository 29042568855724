// BORDER RADIUS (from SG)

.border-radius {
  border-radius: #{map-get($utilities-custom-radius, '4')};
}

// SIZES
@each $name, $size in $utilities-custom-radius {
  .border-radius-#{$name} {
    border-radius: #{$size} !important;
  }
}

// TYPES
.border-radius-end {
  @include border-end-radius(#{map-get($utilities-custom-radius, '4')});
}

@each $name, $size in $utilities-custom-radius {
  .border-radius-end-#{$name} {
    @include border-end-radius($size);
  }
}

.border-radius-start {
  @include border-start-radius(#{map-get($utilities-custom-radius, '4')});
}

@each $name, $size in $utilities-custom-radius {
  .border-radius-start-#{$name} {
    @include border-start-radius($size);
  }
}

.border-radius-top {
  @include border-top-radius(#{map-get($utilities-custom-radius, '4')});
}

@each $name, $size in $utilities-custom-radius {
  .border-radius-top-#{$name} {
    @include border-top-radius($size);
  }
}

.border-radius-bottom {
  @include border-bottom-radius(#{map-get($utilities-custom-radius, '4')});
}

@each $name, $size in $utilities-custom-radius {
  .border-radius-bottom-#{$name} {
    @include border-bottom-radius($size);
  }
}

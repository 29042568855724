@import 'bootstrap/scss/forms/form-select';

$select-disabled-opacity: 0.4 !default;
$select-disabled-box-shadow: inset 0 -0.0625rem 0 $input-disabled-border-color !default;

select {
  &:disabled,
  &[readonly] {
    box-shadow: $input-disabled-box-shadow;

    & ~ .input-group-text {
      background-color: transparent;
      box-shadow: $input-disabled-box-shadow;
    }
  }

  &:disabled {
    opacity: $input-disabled-opacity;

    & ~ .input-group-text {
      background-color: unset;
      opacity: $select-disabled-opacity;
      box-shadow: $select-disabled-box-shadow;
    }
  }

  &:not(.form-control-sm):not(.form-control-lg):not(.form-control-xl):not(.form-select-sm):not(.form-select-lg):not(
      .form-select-xl
    ) {
    min-height: $input-height !important;
  }

  &:hover:not(:disabled):not(:focus-visible):not(:focus):not([class^='is-']) {
    box-shadow: inset 0 0 0 1px $input-btn-focus-color !important;
  }

  &.form-select {
    padding-top: calc(($input-height - $input-line-height) / 2);
    padding-bottom: calc(($input-height - $input-line-height) / 2);
    background-image: escape-svg(
      url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' fill='#{$secondary}'><path d='M0 0h24v24H0z' fill='none'/><path d='M7 10l5 5 5-5z'/></svg>")
    );
    background-size: $btn-icon-size;
    &:hover:not([disabled]):not(.disabled),
    &:focus:not([disabled]):not(.disabled) {
      background-image: escape-svg(
        url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' fill='#{$primary}'><path d='M0 0h24v24H0z' fill='none'/><path d='M7 10l5 5 5-5z'/></svg>")
      );
    }
  }
  &.form-select-sm {
    line-height: $input-line-height-sm;
    padding-top: calc(($input-height-sm - $input-line-height-sm) / 2);
    padding-bottom: calc(($input-height-sm - $input-line-height-sm) / 2);
    min-height: $input-height-sm;
    background-size: $btn-icon-size-sm;
  }
  &.form-select-lg {
    padding-top: calc(($input-height-lg - $input-line-height-lg) / 2);
    padding-bottom: calc(($input-height-lg - $input-line-height-lg) / 2);
    line-height: $input-line-height-lg;
    min-height: $input-height-lg;
    background-size: $btn-icon-size-lg;
  }
  &.form-select-xl {
    padding-top: calc(($input-height-xl - $input-line-height-xl) / 2);
    padding-bottom: calc(($input-height-xl - $input-line-height-xl) / 2);
    line-height: $input-line-height-xl;
    min-height: $input-height-xl;
    background-size: $btn-icon-size-xl;
  }

  &:not([multiple]) > option,
  optgroup {
    background-color: $primary-alt;
  }

  // ALT STYLE
  &.form-select-outline {
    background-color: transparent;
    box-shadow: inset 0 0 0 1px $form-control-outline-border-color;
  }
}

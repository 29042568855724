@use 'sass:math';

$progress-molecule-item-size: 1.5rem;
$progress-molecule-item-border-width: 2px;
$progress-molecule-label-font-weight: $font-weight-medium !default;
$progress-molecule-label-font-size: $font-size-base !default;
$progress-icon-color: $primary-alt !default;

.stepper {
  padding-left: 0;
  list-style: none;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
}

.stepper-step {
  flex: 2;
  position: relative;

  &.inactive {
    color: $progress-molecule-inactive-color;

    .stepper-label {
      color: $progress-molecule-inactive-label-color !important;
    }
  }

  &.blocked {
    .stepper-step-inner::after {
      color: $progress-molecule-inactive-color;
    }

    &.muted {
      .stepper-step-inner::before {
        color: $primary;
      }
    }
  }
}

.stepper-step-inner {
  display: flex;
  justify-content: space-between;
  align-items: center;

  &::before,
  &::after {
    content: '';
    flex: 1;
    height: $progress-molecule-item-border-width;
    background: currentColor;
  }
}

.stepper-label {
  font-weight: $progress-molecule-label-font-weight;
  margin-top: 0.5rem;
  font-size: $progress-molecule-label-font-size;
  display: block;

  .stepper:not(.stepper-vertical) & {
    text-align: center;
  }
}

.stepper-indicator {
  width: $progress-molecule-item-size;
  height: $progress-molecule-item-size;
  border-radius: 50%;
  border: $progress-molecule-item-border-width solid currentColor;
  text-align: center;
  background: transparent;
  display: flex;
  justify-content: center;
  align-items: center;

  .fill & {
    background: currentColor;
  }
}

.stepper-no-indicator {
  height: $progress-molecule-item-size;
  text-align: center;
  background: transparent;
  display: flex;
  justify-content: center;
  align-items: center;
}

// ICONS
.stepper-indicator-current {
  height: 100%;
  width: 100%;
  background-color: currentColor;
  mask-position: center;
  mask-repeat: no-repeat;
  mask-image: url("data:image/svg+xml,%3Csvg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Ccircle cx='12' cy='12' r='5' fill='black'/%3E%3C/svg%3E%0A");
  -webkit-mask-image: url("data:image/svg+xml,%3Csvg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Ccircle cx='12' cy='12' r='5' fill='black'/%3E%3C/svg%3E%0A");
  -webkit-mask-position: center;
  -webkit-mask-repeat: no-repeat;
}

.stepper-indicator-complete {
  height: 100%;
  width: 100%;
  background-color: $progress-icon-color;
  mask-position: center;
  mask-repeat: no-repeat;
  mask-image: url("data:image/svg+xml,%3Csvg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M9.35802 15.1786L5.92055 11.758L4.75 12.9146L9.35802 17.5L19.25 7.6566L18.0877 6.5L9.35802 15.1786Z' fill='black'/%3E%3C/svg%3E%0A");
  -webkit-mask-image: url("data:image/svg+xml,%3Csvg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M9.35802 15.1786L5.92055 11.758L4.75 12.9146L9.35802 17.5L19.25 7.6566L18.0877 6.5L9.35802 15.1786Z' fill='black'/%3E%3C/svg%3E%0A");
  -webkit-mask-position: center;
  -webkit-mask-repeat: no-repeat;
}

.stepper-indicator-warning {
  height: 100%;
  width: 100%;
  background-color: currentColor;
  mask-position: center;
  mask-repeat: no-repeat;
  mask-image: url("data:image/svg+xml,%3Csvg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M13.025 5.5L12.8625 14.6761H11.1313L10.9688 5.5H13.025ZM12.0001 18.5C11.6542 18.5 11.3584 18.3784 11.1126 18.1352C10.8668 17.8878 10.7459 17.5901 10.7501 17.2421C10.7459 16.8983 10.8668 16.6048 11.1126 16.3616C11.3584 16.1143 11.6542 15.9906 12.0001 15.9906C12.3375 15.9906 12.6292 16.1143 12.875 16.3616C13.1208 16.6048 13.2458 16.8983 13.25 17.2421C13.2458 17.4727 13.1854 17.6845 13.0688 17.8774C12.9563 18.066 12.8063 18.217 12.6188 18.3302C12.4313 18.4434 12.225 18.5 12.0001 18.5Z' fill='black'/%3E%3C/svg%3E");
  -webkit-mask-image: url("data:image/svg+xml,%3Csvg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M13.025 5.5L12.8625 14.6761H11.1313L10.9688 5.5H13.025ZM12.0001 18.5C11.6542 18.5 11.3584 18.3784 11.1126 18.1352C10.8668 17.8878 10.7459 17.5901 10.7501 17.2421C10.7459 16.8983 10.8668 16.6048 11.1126 16.3616C11.3584 16.1143 11.6542 15.9906 12.0001 15.9906C12.3375 15.9906 12.6292 16.1143 12.875 16.3616C13.1208 16.6048 13.2458 16.8983 13.25 17.2421C13.2458 17.4727 13.1854 17.6845 13.0688 17.8774C12.9563 18.066 12.8063 18.217 12.6188 18.3302C12.4313 18.4434 12.225 18.5 12.0001 18.5Z' fill='black'/%3E%3C/svg%3E");
  -webkit-mask-position: center;
  -webkit-mask-repeat: no-repeat;
}

.stepper-indicator-failed {
  height: 100%;
  width: 100%;
  background-color: $progress-icon-color;
  mask-position: center;
  mask-repeat: no-repeat;
  mask-image: url("data:image/svg+xml,%3Csvg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M18 7.20857L16.7914 6L12 10.7914L7.20857 6L6 7.20857L10.7914 12L6 16.7914L7.20857 18L12 13.2086L16.7914 18L18 16.7914L13.2086 12L18 7.20857Z' fill='black'/%3E%3C/svg%3E");
  -webkit-mask-image: url("data:image/svg+xml,%3Csvg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M18 7.20857L16.7914 6L12 10.7914L7.20857 6L6 7.20857L10.7914 12L6 16.7914L7.20857 18L12 13.2086L16.7914 18L18 16.7914L13.2086 12L18 7.20857Z' fill='black'/%3E%3C/svg%3E");
  -webkit-mask-position: center;
  -webkit-mask-repeat: no-repeat;
}

/* Exceptions */

.stepper-step:not(.current) .icon {
  color: $progress-icon-color;
  height: 0.8rem;
  line-height: 0.8rem;
}

button.stepper-indicator {
  cursor: pointer;
  padding: 0;

  @each $color, $value in $theme-colors {
    .text-#{$color} & {
      color: $value;
    }
  }
}

.stepper .stepper-step {
  &:first-child {
    flex: 1;

    .stepper-step-inner::before {
      display: none;
    }

    .stepper-label {
      text-align: left;
    }
  }

  &:last-child {
    flex: 1;

    .stepper-step-inner::after {
      display: none;
    }

    .stepper-label {
      text-align: right;
    }
  }
}

.stepper.stepper-vertical .stepper-step:last-child .stepper-label {
  text-align: left;
}

/* Vertical */
.stepper-vertical {
  height: 100%;
  flex-direction: column;

  .stepper-step-inner {
    flex-direction: column;
    height: 100%;
  }

  .stepper-step-inner {
    flex-direction: column;
    height: 100%;

    &::before,
    &::after {
      content: '';
      width: $progress-molecule-item-border-width;
      height: 100%;
    }
  }

  .stepper-label {
    position: absolute;
    margin-top: 0;
    top: 0;
    bottom: 0;
    display: flex;
    align-items: center;
    left: 2 * $progress-molecule-item-size;
    white-space: nowrap;
  }

  .stepper-step {
    &:first-child {
      .stepper-label {
        top: 0;
        bottom: auto;
      }
    }

    &:last-child {
      .stepper-label {
        bottom: 0;
        top: auto;
      }
    }
  }
}

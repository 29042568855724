@import 'bootstrap/scss/tables';

th {
  color: $secondary;
  font-weight: $font-weight-medium;
}

tr {
  --#{$prefix}table-hover-bg: rgba(var(--#{$prefix}primary-rgb), 0.1) !important;
  --#{$prefix}table-active-bg: rgba(var(--#{$prefix}primary-rgb), 0.1) !important;
}

.table > thead {
  th {
    --#{$prefix}border-color: #{$primary} !important;
    border-bottom-color: var(--#{$prefix}border-color);
  }
}

.table {
  *:has(+ .table-active) {
    border-bottom-color: var(--#{$prefix}table-active-bg);
  }

  .table-active {
    outline: 0.0625rem solid $primary;

    @each $name, $color in $theme-colors {
      &.table-#{$name} {
        outline-color: $color;
        --#{$prefix}table-active-color: #{$color};
        --#{$prefix}table-active-bg: var(--#{$prefix}table-bg);

        > * {
          box-shadow: none;
        }
      }
    }

    > * {
      border-width: 0;
    }

    ~ .table-active {
      outline: none !important;
      box-shadow:
        /* Bottom shadow */ 0px 0.0625rem 0px $primary, /* Left shadow */ -0.0625rem 0px 0px $primary,
        /* Right shadow */ 0.0625rem 0px 0px $primary, /* No top shadow */ 0px 0px 0px 0px;
    }
  }
}

.table-bordered > thead {
  th {
    border-bottom-width: 1px;
  }
}

@mixin set-opacity-rules() {
  @each $opacity in map-keys($opacities) {
    .opacity-#{$opacity} {
      opacity: map-get($opacities, $opacity) !important;
    }
    .bg-opacity-#{$opacity} {
      --#{$prefix}bg-opacity: #{map-get($opacities, $opacity)} !important;
    }
    .text-opacity-#{$opacity} {
      --#{$prefix}text-opacity: #{map-get($opacities, $opacity)} !important;
    }
  }
}

@mixin set-background-opacity-rules($colors) {
  @each $opacity in map-keys($opacities) {
    @each $color_name, $color_value in $colors {
      .bg-#{$color_name} {
        &.bg-opacity-#{$opacity} {
          background: rgba($color_value, map-get($opacities, $opacity)) !important;
        }
      }
    }
  }
}

@mixin set-background-alt-opacity-rules($colors) {
  @each $opacity in map-keys($opacities) {
    @each $color_name, $color_value in $colors {
      .bg-alt-#{$color_name} {
        &.bg-opacity-#{$opacity} {
          background: rgba($color_value, map-get($opacities, $opacity)) !important;
        }
      }
    }
  }
}

@include set-opacity-rules();
@include set-background-opacity-rules($custom-bg-colors);
@include set-background-alt-opacity-rules($bg-alt-colors);
@include set-background-opacity-rules($secondary-colors);

@import 'bootstrap/scss/functions';

.text-success:not(h1):not(.h1):not(h2):not(.h2):not(h3):not(.h3):not(.display-1):not(.display-2):not(.display-3):not(
    .display-4
  ) {
  --#{$prefix}success-rgb: #{to-rgb(text-color-light-background('bg-lvl1', 'success'))};
}
.text-warning:not(h1):not(.h1):not(h2):not(.h2):not(h3):not(.h3):not(.display-1):not(.display-2):not(.display-3):not(
    .display-4
  ) {
  --#{$prefix}warning-rgb: #{to-rgb(text-color-light-background('bg-lvl1', 'warning'))};
}

$adapted_text_colors: ('primary', 'secondary', 'success', 'info', 'warning', 'danger', 'black', 'dark');
@each $color in $adapted_text_colors {
  .bg-#{$color} {
    color: $primary-alt;
  }
  @each $opacity in map-keys($opacities) {
    .bg-#{$color} {
      &.bg-opacity-#{$opacity} {
        color: $primary;
      }
    }
  }
}

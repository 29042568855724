@import 'bootstrap/scss/toasts';

@each $color, $value in $theme-colors {
  .toast-#{$color} {
    .toast-header {
      color: $value;
    }
  }
}

.toast {
  --#{$prefix}toast-border-color: #{$border-color};
}
.toast-header {
  background-color: var(--#{$prefix}toast-bg);
  --#{$prefix}toast-header-border-color: #{$hr-border-color};
  &::before {
    content: '';
    width: 0.5rem;
    height: 0.5rem;
    margin-right: 0.5rem;
    display: inline-block;
    background-color: currentColor;
  }

  .btn-close {
    padding: 0 !important;
    margin-right: 0;
    height: px-to-rem(14);
    width: px-to-rem(14);
  }
}

.toast-footer {
  --#{$prefix}toast-header-border-color: #{$hr-border-color};
  border-top: var(--#{$prefix}toast-border-width) solid var(--#{$prefix}toast-header-border-color);
  display: flex;
  align-items: center;

  .btn {
    flex: 1;
    border: none;

    & + .btn {
      border-left: 1px solid var(--#{$prefix}toast-header-border-color);
    }
  }
}

// NOTIFICATION
.notification {
  @extend .toast;
}

.notification-header {
  @extend .toast-header;
}

.notification-timestamp {
  color: $secondary !important;
}

.notification-body {
  @extend .toast-body;
}

.notification-footer {
  @extend .toast-footer;
}

@each $color, $value in $theme-colors {
  .notification-#{$color} {
    @extend .toast-#{$color};
  }
}

// POP-UP
.pop-up {
  @extend .toast;
  max-width: 320px;
  padding: px-to-rem(16);
  text-align: center;
  box-shadow: $box-shadow-max;
  animation: slideUp 300ms;
  animation-fill-mode: forwards;
}

@each $name, $color in $theme-colors {
  @if ($name != 'primary-alt' and $name != 'light') {
    .pop-up-#{$name} {
      --#{$prefix}toast-color: #{$primary-alt} !important;
    }
  }

  .pop-up-#{$name} {
    --#{$prefix}toast-bg: #{$color} !important;
    --#{$prefix}toast-border-color: #{$color};
  }
}

@keyframes slideUp {
  from {
    transform: translate(0, 10rem);
  }
  to {
    transform: translate(0, 0);
  }
}

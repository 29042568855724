$font-path: './fonts' !default;
$font-display: auto !default;

@font-face {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 100;
  font-display: $font-display;
  src: url('#{$font-path}/Inter/Inter-Thin.woff2?v=3.15') format('woff2'),
    url('#{$font-path}/Inter/Inter-Thin.woff?v=3.15') format('woff');
}
@font-face {
  font-family: 'Inter';
  font-style: italic;
  font-weight: 100;
  font-display: $font-display;
  src: url('#{$font-path}/Inter/Inter-ThinItalic.woff2?v=3.15') format('woff2'),
    url('#{$font-path}/Inter/Inter-ThinItalic.woff?v=3.15') format('woff');
}

@font-face {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 200;
  font-display: $font-display;
  src: url('#{$font-path}/Inter/Inter-ExtraLight.woff2?v=3.15') format('woff2'),
    url('#{$font-path}/Inter/Inter-ExtraLight.woff?v=3.15') format('woff');
}
@font-face {
  font-family: 'Inter';
  font-style: italic;
  font-weight: 200;
  font-display: $font-display;
  src: url('#{$font-path}/Inter/Inter-ExtraLightItalic.woff2?v=3.15') format('woff2'),
    url('#{$font-path}/Inter/Inter-ExtraLightItalic.woff?v=3.15') format('woff');
}

@font-face {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 300;
  font-display: $font-display;
  src: url('#{$font-path}/Inter/Inter-Light.woff2?v=3.15') format('woff2'),
    url('#{$font-path}/Inter/Inter-Light.woff?v=3.15') format('woff');
}
@font-face {
  font-family: 'Inter';
  font-style: italic;
  font-weight: 300;
  font-display: $font-display;
  src: url('#{$font-path}/Inter/Inter-LightItalic.woff2?v=3.15') format('woff2'),
    url('#{$font-path}/Inter/Inter-LightItalic.woff?v=3.15') format('woff');
}

@font-face {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 400;
  font-display: $font-display;
  src: url('#{$font-path}/Inter/Inter-Regular.woff2?v=3.15') format('woff2'),
    url('#{$font-path}/Inter/Inter-Regular.woff?v=3.15') format('woff');
}
@font-face {
  font-family: 'Inter';
  font-style: italic;
  font-weight: 400;
  font-display: $font-display;
  src: url('#{$font-path}/Inter/Inter-Italic.woff2?v=3.15') format('woff2'),
    url('#{$font-path}/Inter/Inter-Italic.woff?v=3.15') format('woff');
}

@font-face {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 500;
  font-display: $font-display;
  src: url('#{$font-path}/Inter/Inter-Medium.woff2?v=3.15') format('woff2'),
    url('#{$font-path}/Inter/Inter-Medium.woff?v=3.15') format('woff');
}
@font-face {
  font-family: 'Inter';
  font-style: italic;
  font-weight: 500;
  font-display: $font-display;
  src: url('#{$font-path}/Inter/Inter-MediumItalic.woff2?v=3.15') format('woff2'),
    url('#{$font-path}/Inter/Inter-MediumItalic.woff?v=3.15') format('woff');
}

@font-face {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 600;
  font-display: $font-display;
  src: url('#{$font-path}/Inter/Inter-SemiBold.woff2?v=3.15') format('woff2'),
    url('#{$font-path}/Inter/Inter-SemiBold.woff?v=3.15') format('woff');
}
@font-face {
  font-family: 'Inter';
  font-style: italic;
  font-weight: 600;
  font-display: $font-display;
  src: url('#{$font-path}/Inter/Inter-SemiBoldItalic.woff2?v=3.15') format('woff2'),
    url('#{$font-path}/Inter/Inter-SemiBoldItalic.woff?v=3.15') format('woff');
}

@font-face {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 700;
  font-display: $font-display;
  src: url('#{$font-path}/Inter/Inter-Bold.woff2?v=3.15') format('woff2'),
    url('#{$font-path}/Inter/Inter-Bold.woff?v=3.15') format('woff');
}
@font-face {
  font-family: 'Inter';
  font-style: italic;
  font-weight: 700;
  font-display: $font-display;
  src: url('#{$font-path}/Inter/Inter-BoldItalic.woff2?v=3.15') format('woff2'),
    url('#{$font-path}/Inter/Inter-BoldItalic.woff?v=3.15') format('woff');
}

@font-face {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 800;
  font-display: $font-display;
  src: url('#{$font-path}/Inter/Inter-ExtraBold.woff2?v=3.15') format('woff2'),
    url('#{$font-path}/Inter/Inter-ExtraBold.woff?v=3.15') format('woff');
}
@font-face {
  font-family: 'Inter';
  font-style: italic;
  font-weight: 800;
  font-display: $font-display;
  src: url('#{$font-path}/Inter/Inter-ExtraBoldItalic.woff2?v=3.15') format('woff2'),
    url('#{$font-path}/Inter/Inter-ExtraBoldItalic.woff?v=3.15') format('woff');
}

@font-face {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 900;
  font-display: $font-display;
  src: url('#{$font-path}/Inter/Inter-Black.woff2?v=3.15') format('woff2'),
    url('#{$font-path}/Inter/Inter-Black.woff?v=3.15') format('woff');
}
@font-face {
  font-family: 'Inter';
  font-style: italic;
  font-weight: 900;
  font-display: $font-display;
  src: url('#{$font-path}/Inter/Inter-BlackItalic.woff2?v=3.15') format('woff2'),
    url('#{$font-path}/Inter/Inter-BlackItalic.woff?v=3.15') format('woff');
}

// NOTO

@font-face {
  font-family: 'Noto_Sans_JP';
  font-display: $font-display;
  font-style: normal;
  font-weight: 400;
  src: url('#{$font-path}/NotoSansJP/noto-sans-jp-v28-japanese-regular.woff2?v=3.15') format('woff2'),
    url('#{$font-path}/NotoSansJP/noto-sans-jp-v28-japanese-regular.woff?v=3.15') format('woff');
}

@font-face {
  font-family: 'Noto_Sans_JP';
  font-display: $font-display;
  font-style: normal;
  font-weight: 300;
  src: url('#{$font-path}/NotoSansJP/noto-sans-jp-v28-japanese-300.woff2?v=3.15') format('woff2'),
    url('#{$font-path}/NotoSansJP/noto-sans-jp-v28-japanese-300.woff?v=3.15') format('woff');
}

@font-face {
  font-family: 'Noto_Sans_JP';
  font-display: $font-display;
  font-style: normal;
  font-weight: 500;
  src: url('#{$font-path}/NotoSansJP/noto-sans-jp-v28-japanese-500.woff2?v=3.15') format('woff2'),
    url('#{$font-path}/NotoSansJP/noto-sans-jp-v28-japanese-500.woff?v=3.15') format('woff');
}

@font-face {
  font-family: 'Noto_Sans_JP';
  font-display: $font-display;
  font-style: normal;
  font-weight: 700;
  src: url('#{$font-path}/NotoSansJP/noto-sans-jp-v28-japanese-700.woff2?v=3.15') format('woff2'),
    url('#{$font-path}/NotoSansJP/noto-sans-jp-v28-japanese-700.woff?v=3.15') format('woff');
}

@font-face {
  font-family: 'Noto_Sans_JP';
  font-display: $font-display;
  font-style: normal;
  font-weight: 900;
  src: url('#{$font-path}/NotoSansJP/noto-sans-jp-v28-japanese-900.woff2?v=3.15') format('woff2'),
    url('#{$font-path}/NotoSansJP/noto-sans-jp-v28-japanese-900.woff?v=3.15') format('woff');
}

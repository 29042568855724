@use 'sass:math';

@import 'bootstrap/scss/navbar';

$navbar-height: 4rem;
$navbar-height-sm: 3.5rem;
$navbar-height-lg: 5rem;

$navbar-img-size: 1.75rem;
$navbar-img-size-sm: 1.5rem;
$navbar-img-size-lg: 2rem;

$navbar-link-padding: 1.125rem;
$navbar-link-padding-sm: 1rem;
$navbar-link-padding-lg: 1.5rem;

.navbar {
  height: $navbar-height-sm;
  display: flex;
  align-items: center;
  flex-wrap: nowrap;

  .navbar-title,
  .navbar-toolbar {
    flex: 1;
    display: flex;
    min-width: -webkit-min-content;
  }

  .navbar-menu-btn {
    display: block;
    align-self: center;
  }

  .navbar-menu-dropdown {
    width: 100%;
    border-width: 0 0 1px 0;
    box-shadow: unset;
  }

  .navbar-content {
    flex: 100%;
    align-items: center;
    display: none;
  }

  .navbar-content-center {
    flex: 0;
    flex-basis: fit-content;
  }

  .navbar-content-container {
    flex-grow: unset !important;
  }

  .navbar-navigation {
    display: flex;
    list-style: none;
    padding: 0;
  }

  .navbar-link {
    min-height: $navbar-height-sm;
    background: rgba(255, 255, 255, 0);
    font-size: 0.875rem;
    letter-spacing: -0.01em;
    color: $secondary;
    font-weight: $font-weight-semibold;
    border: none;
    padding: 0 $navbar-link-padding;
    display: flex;
    align-items: center;
    &.dropdown-toggle {
      margin-left: 0;
      &::after {
        background-color: $secondary;
      }
    }
    &.disabled {
      color: rgba($primary, 0.4);
      pointer-events: none;
      cursor: default;
      font-weight: $font-weight-medium;
      &.dropdown-toggle::after {
        opacity: 0.4;
      }
    }
  }

  .navbar-link:hover,
  .navbar-link:focus-visible {
    color: $primary;
    text-decoration: none;
    background: linear-gradient(
      180deg,
      rgba($navbar-link-background, $navbar-link-background, $navbar-link-background, 0.1) 0%,
      rgba($navbar-link-background, $navbar-link-background, $navbar-link-background, 0.08) 15%,
      rgba($navbar-link-background, $navbar-link-background, $navbar-link-background, 0.06) 25%,
      rgba($navbar-link-background, $navbar-link-background, $navbar-link-background, 0.05) 50%,
      rgba($navbar-link-background, $navbar-link-background, $navbar-link-background, 0) 130%
    );
    &.dropdown-toggle::after {
      background-color: $primary;
    }
  }

  .navbar-link:active {
    color: $primary;
    background: linear-gradient(
      180deg,
      rgba($navbar-link-background, $navbar-link-background, $navbar-link-background, 0.22) 0%,
      rgba($navbar-link-background, $navbar-link-background, $navbar-link-background, 0.15) 15%,
      rgba($navbar-link-background, $navbar-link-background, $navbar-link-background, 0.12) 25%,
      rgba($navbar-link-background, $navbar-link-background, $navbar-link-background, 0.1) 50%,
      rgba($navbar-link-background, $navbar-link-background, $navbar-link-background, 0) 130%
    );
  }

  .navbar-link.active {
    box-shadow: inset 0 2px $primary !important;
    color: $primary;
    background: linear-gradient(
      180deg,
      rgba($navbar-link-background, $navbar-link-background, $navbar-link-background, 0.2) 0%,
      rgba($navbar-link-background, $navbar-link-background, $navbar-link-background, 0.12) 15%,
      rgba($navbar-link-background, $navbar-link-background, $navbar-link-background, 0.1) 25%,
      rgba($navbar-link-background, $navbar-link-background, $navbar-link-background, 0.04) 50%,
      rgba($navbar-link-background, $navbar-link-background, $navbar-link-background, 0) 90%
    );
    &.dropdown-toggle::after {
      background-color: $primary;
    }
  }

  .navbar-link.active:hover,
  .navbar-link.active:focus-visible {
    background: linear-gradient(
      180deg,
      rgba($navbar-link-background, $navbar-link-background, $navbar-link-background, 0.25) 0%,
      rgba($navbar-link-background, $navbar-link-background, $navbar-link-background, 0.16) 15%,
      rgba($navbar-link-background, $navbar-link-background, $navbar-link-background, 0.15) 20%,
      rgba($navbar-link-background, $navbar-link-background, $navbar-link-background, 0.1) 60%,
      rgba($navbar-link-background, $navbar-link-background, $navbar-link-background, 0) 130%
    );
  }

  .navbar-link.active:active {
    background: linear-gradient(
      180deg,
      rgba($navbar-link-background, $navbar-link-background, $navbar-link-background, 0.3) 0%,
      rgba($navbar-link-background, $navbar-link-background, $navbar-link-background, 0.2) 15%,
      rgba($navbar-link-background, $navbar-link-background, $navbar-link-background, 0.18) 20%,
      rgba($navbar-link-background, $navbar-link-background, $navbar-link-background, 0.15) 60%,
      rgba($navbar-link-background, $navbar-link-background, $navbar-link-background, 0) 130%
    );
  }

  .navbar-menu-dropdown .navbar-link {
    min-height: 3rem;
    &.active {
      box-shadow: inset 2px 0px $primary !important;
      background: linear-gradient(
        90deg,
        rgba($navbar-link-background, $navbar-link-background, $navbar-link-background, 0.2) 0%,
        rgba($navbar-link-background, $navbar-link-background, $navbar-link-background, 0.12) 15%,
        rgba($navbar-link-background, $navbar-link-background, $navbar-link-background, 0.1) 25%,
        rgba($navbar-link-background, $navbar-link-background, $navbar-link-background, 0.04) 50%,
        rgba($navbar-link-background, $navbar-link-background, $navbar-link-background, 0) 90%
      );
    }

    &:hover {
      text-decoration: none;
      color: $primary;
      background: linear-gradient(
        90deg,
        rgba($navbar-link-background, $navbar-link-background, $navbar-link-background, 0.1) 0%,
        rgba($navbar-link-background, $navbar-link-background, $navbar-link-background, 0.08) 15%,
        rgba($navbar-link-background, $navbar-link-background, $navbar-link-background, 0.06) 25%,
        rgba($navbar-link-background, $navbar-link-background, $navbar-link-background, 0.05) 50%,
        rgba($navbar-link-background, $navbar-link-background, $navbar-link-background, 0) 130%
      );
    }
  }

  .navbar-toolbar {
    justify-content: flex-end;
    align-items: center;
  }
}

.navbar-title-link {
  min-height: $navbar-height-sm;
  padding: 0 1rem;
  display: flex;
  align-items: center;
  gap: 1.125rem;

  &:hover {
    background: linear-gradient(
      180deg,
      rgba($navbar-link-background, $navbar-link-background, $navbar-link-background, 0.1) 0%,
      rgba($navbar-link-background, $navbar-link-background, $navbar-link-background, 0.08) 15%,
      rgba($navbar-link-background, $navbar-link-background, $navbar-link-background, 0.06) 25%,
      rgba($navbar-link-background, $navbar-link-background, $navbar-link-background, 0.05) 50%,
      rgba($navbar-link-background, $navbar-link-background, $navbar-link-background, 0) 130%
    );
    text-decoration: none;
  }

  .navbar-logo {
    color: $primary !important;
  }

  .navbar-logo,
  .navbar-logo > img {
    display: flex;
    height: $navbar-img-size-sm;
  }

  .navbar-title-divider {
    height: 1.75rem;
    width: 1px;
    background-color: $light;
    display: none;
  }

  .navbar-service-name {
    font-weight: $font-weight-medium;
    color: $primary;
    letter-spacing: 0;
    line-height: 16px;
    padding: 0;
    font-size: 0.875rem;
    display: none;

    > img {
      height: $navbar-img-size-sm;
    }
  }
}

.navbar-sm {
  .navbar-title-divider {
    height: $navbar-img-size-sm;
  }
  .navbar-title-link {
    gap: 1rem;
  }
  .navbar-link {
    padding: 0 $navbar-link-padding-sm;
  }
  .navbar-service-name {
    line-height: 1;
  }
}

.navbar-lg {
  .navbar-title-divider {
    height: $navbar-img-size-lg;
  }
  .navbar-service-name {
    font-size: 1rem;
    line-height: 1.25;
  }
  .navbar-title-link {
    gap: 1.5rem;
  }
  .navbar-link {
    padding: 0 $navbar-link-padding-lg;
  }
}

@include media-breakpoint-up(md) {
  .navbar {
    height: $navbar-height;

    .navbar-link {
      min-height: $navbar-height;
    }
    .navbar-title-divider {
      display: block;
    }
    .navbar-service-name {
      display: block;
      > img {
        height: $navbar-img-size;
      }
    }

    .navbar-menu-dropdown {
      display: none;
    }

    .navbar-content {
      display: flex;
    }

    .navbar-content-container {
      max-width: 720px;
    }

    .navbar-menu-btn {
      display: none !important;
    }

    .navbar-title-link {
      padding: 0 1.5rem;
      min-height: $navbar-height;
      .navbar-logo,
      .navbar-logo > img {
        height: $navbar-img-size;
      }
    }
  }

  .navbar-sm {
    height: $navbar-height-sm;
    .navbar-link {
      min-height: $navbar-height-sm;
    }
    .navbar-title-link {
      min-height: $navbar-height-sm;
      padding: 0 1.25rem 0 1rem;
      .navbar-logo,
      .navbar-logo > img {
        height: $navbar-img-size-sm;
      }
    }
    .navbar-service-name > img {
      height: $navbar-img-size-sm;
    }
  }

  .navbar-lg {
    height: $navbar-height-lg;
    .navbar-link {
      min-height: $navbar-height-lg;
    }
    .navbar-title-link {
      padding: 0 2rem 0 1.5rem;
      min-height: $navbar-height-lg;
      .navbar-logo,
      .navbar-logo > img {
        height: $navbar-img-size-lg;
      }
    }
    .navbar-service-name > img {
      height: $navbar-img-size-lg;
    }
  }
}

@include media-breakpoint-up(sm) {
  .navbar {
    .navbar-content-container {
      max-width: 516px;
    }
  }
}

@include media-breakpoint-up(lg) {
  .navbar {
    .navbar-content-container {
      max-width: 912px;
    }
  }
}

@include media-breakpoint-up(xl) {
  .navbar {
    .navbar-content-container {
      max-width: 1092px;
    }
  }
}

@include media-breakpoint-up(xxl) {
  .navbar {
    .navbar-content-container {
      max-width: 1272px;
    }
  }
}

//
// STATES
//

// Disabled
@mixin disabled-button($color) {
  &.disabled,
  &:disabled {
    background-color: $color;
    border-color: $color;
    box-shadow: none;
  }
}

// Active
@mixin active-button($color) {
  &:not([disabled]):not(.disabled):active,
  &:not([disabled]):not(.disabled).active {
    color: transparentize($primary-alt, 0.25);
    background-color: $color;
    border-color: $color;
  }
}

// Drastic hover
@mixin drastic-hover-contrast($color, $hover-color) {
  &:hover,
  &:focus {
    &:not([disabled]):not(.disabled) {
      color: $primary-alt;
      background-color: $hover-color;
      border-color: $hover-color;
    }
  }
}

//
// STYLES
//

// Color variant
@mixin custom-button-variant($color, $hover-color) {
  --#{$prefix}btn-color: #{$primary-alt};
  --#{$prefix}btn-bg: #{$color};
  --#{$prefix}btn-border-color: #{$color};

  @include drastic-hover-contrast($color, $hover-color);
  @include disabled-button($color);
  @include active-button($color);
}

// Outline
@mixin btn-outline-variant($color) {
  --#{$prefix}btn-color: #{$color} !important;
  --#{$prefix}btn-bg: transparent !important;

  --#{$prefix}btn-hover-bg: #{$color} !important;
  --#{$prefix}btn-hover-color: #{$btn-color} !important;

  --#{$prefix}btn-shadow: transparent;
  --#{$prefix}btn-hover-shadow: transparent;
  --#{$prefix}btn-active-shadow: transparent;

  box-shadow: inset 0 0 0 1px $color;

  &:not(:disabled):not(.disabled):active,
  &:not(:disabled):not(.disabled).active {
    --#{$prefix}btn-color: transparent;
    box-shadow: inset 0 0 0 1px $color;
  }

  &:focus,
  &.focus {
    --#{$prefix}btn-color: #{$primary-alt} !important;
    --#{$prefix}btn-bg: #{$color} !important;
  }

  &.disabled,
  &:disabled {
    color: $color;
    background-color: transparent;
  }
}

// Flat
@mixin btn-flat-variant($color) {
  --#{$prefix}btn-bg: transparent !important;
  --#{$prefix}btn-color: #{$color} !important;
  --#{$prefix}btn-disabled-color: #{$color} !important;

  --#{$prefix}btn-active-color: #{$color} !important;
  --#{$prefix}btn-active-border-color: transparent !important;
  --#{$prefix}btn-active-bg: #{get-interaction-color('onclick', $color)} !important;

  --#{$prefix}btn-hover-color: #{$color} !important;
  --#{$prefix}btn-hover-bg: #{get-interaction-color('hover', $color)} !important;

  --#{$prefix}btn-focus-ring-color: #{$color} !important;
  --#{$prefix}btn-focus-bg: transparent !important;

  &:hover {
    text-decoration: none;
  }

  &:focus,
  &.focus {
    box-shadow: none;
  }

  // Ensure that hover effect don't affect the default disabled state
  // Unchanged from bootstrap@4.0.0
  // see https://github.com/twbs/bootstrap/blob/v4.0.0/scss/_buttons.scss#L100-L103
  &:disabled,
  &.disabled {
    --#{$prefix}btn-bg: transparent;
    color: var(--#{$prefix}btn-disabled-color) !important;
  }
}

// Discreet
@mixin button-discreet-variant($color) {
  color: $color;
  background-color: rgba($color, $discreet-btn-gradient);

  @include drastic-hover-contrast($color, $color);
  @include active-button($color);

  // Ensure that hover effect don't affect the default disabled state
  // Unchanged from bootstrap@4.0.0
  // see https://github.com/twbs/bootstrap/blob/v4.0.0/scss/_buttons.scss#L100-L103
  &:disabled,
  &.disabled {
    color: $color;
    background-color: transparent;
  }
}

@mixin btn-sizing($padding-y, $padding-x, $icon-size) {
  // excluded [data-toggle='collapse'] to prevent breaking accordion arrow
  // introduced class .sgbs-square-collapse to specifically create a square
  // collapse toggler made of an icon inside a [data-toggle='collapse'] button
  &:not([data-toggle='collapse']) > .icon,
  &.sgbs-square-collapse > .icon {
    margin-left: $padding-y - $padding-x;
    margin-right: $padding-y - $padding-x;
    font-size: $icon-size;
    line-height: 1;
  }
}

@mixin btn-icon-text-sizing($icon-size, $gap, $padding-y) {
  &.btn-icon-start,
  &.btn-icon-end {
    gap: $gap;

    .icon {
      font-size: $icon-size !important;
      line-height: $icon-size !important;
      margin: 0 !important;
      height: $icon-size;
    }
  }

  &.btn-icon-end {
    padding-right: $padding-y !important;
  }
  &.btn-icon-start {
    padding-left: $padding-y !important;
  }
}

@mixin button-in-card-states($color) {
  &:hover:not(:disabled):not(.disabled) {
    color: $primary-alt;
    background-color: button-hover-background($color);
    border-color: button-hover-background($color);
  }

  &:focus,
  &.focus {
    color: $primary-alt;
  }

  &:not(:disabled):not(.disabled):active,
  &:not(:disabled):not(.disabled).active {
    color: button-active-color($primary-alt);
    background-color: $color;
    border-color: $color;
  }
}

@mixin btn-close-cross($color) {
  --#{$prefix}btn-close-bg: #{escape-svg(
      url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' fill='#{$color}'><path d='M0 0h24v24H0z' fill='none'/><path d='M19 6.41L17.59 5 12 10.59 6.41 5 5 6.41 10.59 12 5 17.59 6.41 19 12 13.41 17.59 19 19 17.59 13.41 12z'/></svg>")
    )};
}

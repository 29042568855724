@import '../functions';

// Figures
.text-figures {
  line-height: 1 !important;
  font-weight: $font-weight-medium !important;
}

.monospace-numbers,
.tabular-figures {
  font-feature-settings: 'cv01', 'cv10', 'tnum' !important;
  letter-spacing: -0.07em !important;
  line-height: 1 !important;
  font-weight: $font-weight-medium !important;
}

// FONT WEIGHT
.fw-extrabold {
  font-weight: $font-weight-extrabold;
}
.fw-medium {
  font-weight: $font-weight-medium;
}

// DECORATIONS
.text-underline {
  @extend .text-decoration-underline;
}
.text-strikethrough {
  @extend .text-decoration-line-through;
}

// Secondary color palette utility
@each $color, $value in map-merge($socgen-specific-colors, $secondary-colors) {
  .text-#{$color} {
    color: $value !important;
  }
}

// adaptive colors on `.text-*`
@each $background in $adaptive-backgrounds {
  .#{$background} {
    @each $name, $adapted_color in map-get($adaptive-palette, $background) {
      --#{$prefix}#{$name}-rgb: #{to-rgb($adapted_color)};
    }
  }
}

// adaptive exceptions on light backgrounds
@each $background in $adaptive-backgrounds-light {
  .#{$background} {
    .text-success:not(h1):not(.h1):not(h2):not(.h2):not(h3):not(.h3):not(.display-1):not(.display-2):not(
        .display-3
      ):not(.display-4) {
      --#{$prefix}success-rgb: #{to-rgb(text-color-light-background($background, 'success'))};
    }
    .text-warning:not(h1):not(.h1):not(h2):not(.h2):not(h3):not(.h3):not(.display-1):not(.display-2):not(
        .display-3
      ):not(.display-4) {
      --#{$prefix}warning-rgb: #{to-rgb(text-color-light-background($background, 'warning'))};
    }

    .text-danger:not(h1):not(.h1):not(h2):not(.h2):not(h3):not(.h3):not(.display-1):not(.display-2):not(.display-3):not(
        .display-4
      ) {
      --#{$prefix}danger-rgb: #{to-rgb(map-get(map-get($adaptive-palette, $background), 'danger'))};
    }
  }
}

// adaptive excceptions on dark backgrounds
@each $background in $adaptive-backgrounds-dark {
  .#{$background} {
    .text-danger:not(h1):not(.h1):not(h2):not(.h2):not(h3):not(.h3):not(.display-1):not(.display-2):not(.display-3):not(
        .display-4
      ) {
      --#{$prefix}danger-rgb: #{to-rgb(danger-text-color-dark-background($background))};
    }

    .text-success:not(h1):not(.h1):not(h2):not(.h2):not(h3):not(.h3):not(.display-1):not(.display-2):not(
        .display-3
      ):not(.display-4) {
      --#{$prefix}success-rgb: #{to-rgb(map-get(map-get($adaptive-palette, $background), 'success'))};
    }
    .text-warning:not(h1):not(.h1):not(h2):not(.h2):not(h3):not(.h3):not(.display-1):not(.display-2):not(
        .display-3
      ):not(.display-4) {
      --#{$prefix}warning-rgb: #{to-rgb(map-get(map-get($adaptive-palette, $background), 'warning'))};
    }
  }
}

// Dataviz colors
@each $name, $palette in $dataviz-colors {
  @each $value, $color in $palette {
    .text-#{$name}-#{$value} {
      color: var(--#{$prefix}#{$name}-#{$value}) !important;
    }
  }
}

// Data label
.data-label {
  font-weight: $font-weight-medium;
  color: $secondary;
}

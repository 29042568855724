@import 'bootstrap/scss/forms/input-group';

@mixin input-group-structure($padding-x) {
  .input-icon-start,
  .input-icon-end {
    display: flex;
    align-items: center;
    justify-content: center;

    .input-group-text {
      height: 100%;
    }
  }

  .input-icon-start {
    left: 0;
  }
  .input-icon-end {
    right: 0;
  }
}

@include input-group-structure($input-padding-x);

.input-group-sm .form-control:not(.form-control-lg):not(.form-control-xl) {
  min-height: $input-height-sm !important;
  padding: $input-padding-y-sm $input-padding-x-sm;
  line-height: $input-line-height-sm;
}

.input-group-lg .form-control:not(.form-control-sm):not(.form-control-xl) {
  min-height: $input-height-lg !important;
  padding: $input-padding-y-lg $input-padding-x-lg;
  line-height: $input-line-height-lg;
}

.input-group-xl .form-control:not(.form-control-sm):not(.form-control-lg) {
  min-height: $input-height-xl !important;
  padding: $input-padding-y-xl $input-padding-x-xl;
  line-height: $input-line-height-xl;
}

.input-icon-start,
.input-icon-end {
  position: absolute;
  top: 0;
  bottom: 0;
  width: $input-height;

  & > .btn {
    padding: 0 !important;
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    height: unset;

    &.dropdown-toggle {
      background-color: $secondary;
      mask-image: $input-icon-caret;
      background-position: center;
      background-size: $btn-icon-size;
      background-repeat: no-repeat;
      &:hover:not([disabled]):not(.disabled),
      &:focus:not([disabled]):not(.disabled) {
        background-color: $primary;
      }
      &::after {
        display: none;
      }
    }

    @each $color, $value in $theme-colors {
      &.dropdown-caret-#{$color} {
        background-color: $value;
      }
    }
  }

  .icon {
    color: $secondary;
  }

  & ~ .form-control {
    height: $input-height-sm;
  }

  // Unset z-index usefull in input-group but causing issue in merged input case
  // When .form-control is focused
  // With the z-index: 3 given by Bootstrap
  // Append or prepend button is not clickable anymore...
  + .form-control:focus {
    z-index: unset;
  }
}

.input-icon-start ~ .form-control {
  padding-left: $input-height;
}
.input-icon-start > .icon,
.input-icon-end > .icon {
  font-size: $btn-icon-size;
}

.input-group-sm {
  .input-icon-start,
  .input-icon-end {
    width: $input-height-sm;

    & ~ .form-control {
      height: $input-height-sm;
    }
  }
  .input-icon-end ~ .form-control {
    padding-right: $input-height-sm !important;
  }
  .input-icon-start ~ .form-control {
    padding-left: $input-height-sm !important;
  }
  .input-icon-start,
  .input-icon-end {
    .icon {
      font-size: $btn-icon-size-sm;
    }
    .dropdown-toggle {
      background-size: $btn-icon-size-sm;
    }
  }
  .input-icon-end > .btn {
    min-height: unset;
    min-width: unset;
  }
}

.input-group-lg {
  .input-icon-start,
  .input-icon-end {
    width: $input-height-lg;

    & ~ .form-control {
      height: $input-height-lg;
    }
  }
  .input-icon-end ~ .form-control {
    padding-right: $input-height-lg !important;
  }
  .input-icon-start ~ .form-control {
    padding-left: $input-height-lg !important;
  }
  .input-icon-start,
  .input-icon-end {
    .icon {
      font-size: $btn-icon-size-lg;
    }
    .dropdown-toggle {
      background-size: $btn-icon-size-lg;
    }
  }
}

.input-group-xl {
  .input-icon-start,
  .input-icon-end {
    width: $input-height-xl;

    & ~ .form-control {
      height: $input-height-xl;
    }
  }
  .input-icon-end ~ .form-control {
    padding-right: $input-height-xl !important;
  }
  .input-icon-start ~ .form-control {
    padding-left: $input-height-xl !important;
  }
  .input-icon-start,
  .input-icon-end {
    .icon {
      font-size: $btn-icon-size-xl;
    }
    .dropdown-toggle {
      background-size: $btn-icon-size-xl;
    }
  }
}

// Placeholders over Light backgrounds
@each $background in $adaptive-backgrounds-light {
  .#{$background} {
    .input-icon-start .icon,
    .input-icon-end .icon {
      color: light-discreet-color($background, 'secondary');
    }
  }
}

// Placeholders over Dark backgrounds
@each $background in $adaptive-backgrounds-dark {
  .#{$background} {
    .input-icon-start .icon,
    .input-icon-end .icon {
      color: dark-discreet-color($background, 'secondary');
    }
  }
}

.input-group-sm {
  font-size: $font-size-sm;
  @include input-group-structure($input-padding-x-sm);
}

.input-group-lg {
  font-size: $font-size-lg;
  @include input-group-structure($input-padding-x-lg);
}

//
// SG Bootstrap font size specifics overrides
//
// Bootstrap use the same font size vars for every components
// ($font-size-base, $font-size-sm, $font-size-lg, ...)
// We do not want to apply $font-size-lg on btn-lg and form-control-lg
// We should declare new variables to set the button and input font sizes, different from
// global font sizes
//
.input-group-lg > .form-control,
.form-control-lg {
  font-size: $input-btn-font-size-lg;
}

// Additional rules missing because XL was introduced and did not exist in bootstrap
.input-group-xl {
  font-size: $input-btn-font-size-xl; // refers to https://sgithub.fr.world.socgen/sgbootstrap/sg-bootstrap/issues/2179

  // Based on bootstrap code set for LG and SM sizes
  // https://github.com/twbs/bootstrap/blob/v4.1.3/scss/_input-group.scss#L120-L135
  > .form-control,
  > .input-icon-start > .input-group-text,
  > .input-icon-end > .input-group-text,
  > .input-icon-start > .btn,
  > .input-icon-end > .btn {
    padding: $input-btn-padding-y-xl $input-btn-padding-x-xl;
    font-size: $input-btn-font-size-xl;
  }

  @include input-group-structure($btn-padding-y-xl);
}

.input-icon-end {
  position: absolute;

  .input-group-text {
    background: transparent;
  }
}

//
// Textual addons
//

.input-group-text {
  padding: $input-padding-y ($input-padding-x * 0.8);
  opacity: 1;
  align-self: center;
  height: calc($input-height);
  min-width: calc($input-height);
  display: flex;
  align-items: center;
  justify-content: center;
  &:has(~ .form-control:disabled),
  &:has(~ .form-select:disabled) {
    background-color: unset;
    opacity: 0.4;
    box-shadow: inset 0 -0.0625rem 0 $input-disabled-border-color;
  }
  &:has(~ .form-control:read-only),
  &:has(~ .form-select:read-only) {
    background-color: transparent;
    box-shadow: inset 0 -0.0625rem 0 $input-disabled-border-color;
  }
}

.input-group-sm {
  .input-group-text {
    height: calc($input-height-sm);
    min-width: calc($input-height-sm);
  }
}

.input-group-lg {
  .input-group-text {
    height: calc($input-height-lg);
    min-width: calc($input-height-lg);
  }
}

.input-group-xl {
  .input-group-text {
    height: calc($input-height-xl);
    min-width: calc($input-height-xl);
  }
}

@use 'sass:math';

@import 'bootstrap/scss/type';
@import 'bootstrap/scss/functions';

// DISPLAYS

$display1-size: px-to-rem(112) !default;
$display2-size: px-to-rem(68) !default;
$display3-size: px-to-rem(42) !default;
$display4-size: px-to-rem(26) !default;
$display5-size: px-to-rem(18) !default;

$display1-letter-spacing: -0.04em !default;
$display2-letter-spacing: -0.035em !default;
$display3-letter-spacing: -0.03em !default;
$display4-letter-spacing: -0.025em !default;
$display5-letter-spacing: -0.02em !default;

$display1-line-height: math.div(112, 112) !default;
$display2-line-height: math.div(72, 68) !default;
$display3-line-height: math.div(44, 42) !default;
$display4-line-height: math.div(28, 26) !default;
$display5-line-height: math.div(20, 18) !default;

.display-1 {
  font-size: $display1-size;
  font-weight: $font-weight-bold;
  letter-spacing: $display1-letter-spacing;
  line-height: $display1-line-height;
}

.display-2 {
  font-size: $display2-size;
  font-weight: $font-weight-bold;
  letter-spacing: $display2-letter-spacing;
  line-height: $display2-line-height;
}
.display-3 {
  font-size: $display3-size;
  font-weight: $font-weight-bold;
  letter-spacing: $display3-letter-spacing;
  line-height: $display3-line-height;
}
.display-4 {
  font-size: $display4-size;
  font-weight: $font-weight-bold;
  letter-spacing: $display4-letter-spacing;
  line-height: $display4-line-height;
}
.display-5 {
  font-size: $display5-size;
  font-weight: $font-weight-bold;
  letter-spacing: $display5-letter-spacing;
  line-height: $display5-line-height;
}

// HEADINGS

h1,
.h1 {
  font-size: $h1-font-size;
  font-weight: $font-weight-semibold;
  letter-spacing: $h1-letter-spacing;
  line-height: $h1-line-height;
}

h2,
.h2 {
  font-size: $h2-font-size;
  font-weight: $font-weight-semibold;
  letter-spacing: $h2-letter-spacing;
  line-height: $h2-line-height;
}

h3,
.h3 {
  font-size: $h3-font-size;
  font-weight: $font-weight-semibold;
  letter-spacing: $h3-letter-spacing;
  line-height: $h3-line-height;
}

h4,
.h4 {
  font-size: $h4-font-size;
  font-weight: $font-weight-semibold;
  letter-spacing: $h4-letter-spacing;
  line-height: $h4-line-height;
}

h5,
.h5 {
  font-size: $h5-font-size;
  font-weight: $font-weight-semibold;
  letter-spacing: $h5-letter-spacing;
  line-height: $h5-line-height;
}

h6,
.h6 {
  font-size: $h6-font-size;
  font-weight: $font-weight-semibold;
  letter-spacing: $h6-letter-spacing;
  line-height: $h6-line-height;
}

// adaptive exceptions on light backgrounds for headings
@each $background in $adaptive-backgrounds-light {
  .#{$background} {
    .display-5,
    .h4,
    h4,
    .h5,
    h5 {
      --#{$prefix}success-rgb: #{to-rgb(text-color-light-background($background, 'success'))};
      --#{$prefix}warning-rgb: #{to-rgb(text-color-light-background($background, 'warning'))};
      --#{$prefix}danger-rgb: #{to-rgb(map-get(map-get($adaptive-palette, $background), 'danger'))};
    }
  }
}

// adaptive exceptions on dark backgrounds
@each $background in $adaptive-backgrounds-dark {
  .#{$background} {
    .display-5,
    .h4,
    h4,
    .h5,
    h5 {
      --#{$prefix}success-rgb: #{to-rgb(map-get(map-get($adaptive-palette, $background), 'success'))};
      --#{$prefix}warning-rgb: #{to-rgb(map-get(map-get($adaptive-palette, $background), 'warning'))};

      --#{$prefix}danger-rgb: #{to-rgb(danger-text-color-dark-background($background))};
    }
  }
}

@import 'forms/floating-labels';
@import 'forms/form-check';
@import 'forms/form-control';
@import 'forms/form-range';
@import 'forms/form-select';
@import 'forms/form-text';
@import 'forms/input-group';
@import 'forms/labels';
@import 'forms/validation';

.input-container {
  display: grid;
  align-items: center;
  grid-template-areas:
    '.'
    '.'
    'HELPTEXT'
    '.';
  gap: 4px;

  label {
    @extend .form-label;
  }
  input[type='text'] {
    @extend .form-control;
  }

  .form-text {
    margin: 0;
    grid-area: HELPTEXT;
  }
}

.input-container-horizontal {
  @extend .input-container;
  grid-auto-columns: max-content;
  grid-template-areas:
    '. .'
    'HELPTEXT HELPTEXT';

  label {
    margin-right: 1rem;
  }
}

//
// Chrome type=search input custom cancel button
// https://developer.mozilla.org/fr/docs/Web/CSS/::-webkit-search-cancel-button
//
@mixin search-cancel-button($size) {
  -webkit-appearance: none;

  height: #{$size}px;
  width: #{$size}px;
  background-image: str-replace(
    url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='#{$size}' height='#{$size}' viewBox='0 0 24 24'%3E%3Cpath fill='#{$primary}' d='M19 6.41L17.59 5 12 10.59 6.41 5 5 6.41 10.59 12 5 17.59 6.41 19 12 13.41 17.59 19 19 17.59 13.41 12z'/%3E%3Cpath d='M0 0h24v24H0z' fill='none'/%3E%3C/svg%3E"),
    '#',
    '%23'
  );
}

.form-control[type='search']::-webkit-search-cancel-button {
  @include search-cancel-button(18);
}

.form-control-sm[type='search']::-webkit-search-cancel-button {
  @include search-cancel-button(16);
}

.form-control-lg[type='search']::-webkit-search-cancel-button {
  @include search-cancel-button(22);
}

.form-control-xl[type='search']::-webkit-search-cancel-button {
  @include search-cancel-button(24);
}

@import 'bootstrap/scss/nav';

$nav-pills-active-border-width: 3px !default;
$nav-link-active-color: $primary !default;

$nav-column-gap-sm: 1rem;
$nav-column-gap: 1.25rem;
$nav-column-gap-lg: 1.5rem;
$nav-column-gap-xl: 2rem;

$global-margin: px-to-rem(4);

$height-sm: px-to-rem(20);
$height: px-to-rem(32);
$height-lg: px-to-rem(40);
$height-xl: px-to-rem(48);

$padding-y-sm: px-to-rem(2);
$padding-y-md: px-to-rem(6);
$padding-y-lg: px-to-rem(10);
$padding-y-xl: px-to-rem(12);

@mixin nav-hover() {
  &:not([disabled]):not(.disabled) {
    &.active {
      background-color: get-interaction-color('selected');
    }
    &:hover,
    &:focus,
    &.focus {
      background-color: get-interaction-color('hover');
    }
    &:active {
      background-color: get-interaction-color('onclick');
    }
  }
}

.nav {
  &:not(.nav-tabs) {
    column-gap: $nav-column-gap;
    .nav-link {
      --#{$prefix}nav-link-padding-x: 0;
      --#{$prefix}nav-link-padding-y: #{$padding-y-md};
    }
  }
}
.nav-link {
  line-height: 1.25rem;

  &.nav-badge {
    .badge {
      margin-left: $global-margin;
    }
  }

  &.nav-icon-start {
    .icon {
      font-size: $btn-icon-size;
      margin-right: $global-margin;
    }
  }
  &.nav-icon-end {
    .icon {
      font-size: $btn-icon-size;
      margin-left: $global-margin;
    }
  }
}

.nav-link.active:not(.disabled) {
  --#{$prefix}nav-link-color: #{$nav-link-active-color};
}

.nav-tabs {
  --#{$prefix}nav-tabs-link-hover-border-color: transparent !important;
  @each $name, $value in $theme-colors {
    // Ensure that the parent border-color is communicated to the child
    &.border-#{$name} {
      .nav-link.active,
      .nav-item.show .nav-link {
        --#{$prefix}nav-tabs-link-active-border-color: #{$value};
        border-bottom-color: transparent !important;
      }
    }
  }
  .nav-link {
    --#{$prefix}nav-link-padding-y: #{px-to-rem(5)};
    --#{$prefix}nav-link-padding-x: #{px-to-rem(10)};
  }
}

// VERTICAL
.nav-vertical {
  flex-direction: column;
}

// SIZING
.nav-sm {
  &:not(.nav-tabs) {
    column-gap: $nav-column-gap-sm;
    .nav-link {
      --#{$prefix}nav-link-padding-y: #{px-to-rem(2)};
    }
  }
  &.nav-tabs {
    .nav-link {
      --#{$prefix}nav-link-padding-y: #{px-to-rem(1)};
    }
  }
  &.nav-underline {
    .nav-link {
      --#{$prefix}nav-link-padding-y: #{px-to-rem(1)} !important;
    }
  }
  .nav-link {
    --#{$prefix}nav-link-font-size: #{$font-size-sm};
    --#{$prefix}nav-link-padding-x: #{px-to-rem(8)};
    line-height: px-to-rem(16);
    &.nav-icon-start {
      .icon {
        font-size: $btn-icon-size-sm;
      }
    }
    &.nav-icon-end {
      .icon {
        font-size: $btn-icon-size-sm;
      }
    }
  }
}
.nav-lg {
  &:not(.nav-tabs) {
    column-gap: $nav-column-gap-lg;
    .nav-link {
      --#{$prefix}nav-link-padding-y: #{$padding-y-lg};
    }
  }
  &.nav-tabs {
    .nav-link {
      --#{$prefix}nav-link-padding-y: #{px-to-rem(9)};
    }
  }
  &.nav-underline {
    .nav-link {
      --#{$prefix}nav-link-padding-y: #{px-to-rem(8.5)} !important;
    }
  }
  .nav-link {
    --#{$prefix}nav-link-padding-x: #{px-to-rem(12)};
    &.nav-icon-start {
      .icon {
        font-size: $btn-icon-size-lg;
      }
    }
    &.nav-icon-end {
      .icon {
        font-size: $btn-icon-size-lg;
      }
    }
  }
}
.nav-xl {
  &:not(.nav-tabs) {
    column-gap: $nav-column-gap-xl;
    .nav-link {
      --#{$prefix}nav-link-padding-y: #{$padding-y-xl};
    }
  }
  &.nav-tabs {
    .nav-link {
      --#{$prefix}nav-link-padding-y: #{px-to-rem(11)};
    }
  }
  &.nav-underline {
    .nav-link {
      --#{$prefix}nav-link-padding-y: #{px-to-rem(10.5)} !important;
    }
  }
  .nav-link {
    --#{$prefix}nav-link-font-size: #{px-to-rem(16)};
    --#{$prefix}nav-link-padding-x: #{px-to-rem(16)};
    line-height: px-to-rem(24);
    &.nav-icon-start {
      .icon {
        font-size: $btn-icon-size-xl;
      }
    }
    &.nav-icon-end {
      .icon {
        font-size: $btn-icon-size-xl;
      }
    }
  }
}

// UNDERLINE
.nav-underline {
  --#{$prefix}nav-underline-link-active-color: #{$nav-link-active-color};
  --#{$prefix}nav-underline-border-width: #{$nav-pills-active-border-width};
  --#{$prefix}nav-underline-gap: 0;

  @each $name, $value in $theme-colors {
    // Ensure that the parent border-color is communicated to the child
    &.border-#{$name} {
      .nav-link.active,
      .nav-item.show .nav-link {
        border-bottom-color: $value;
      }
    }
  }
  .nav-link {
    --#{$prefix}nav-link-padding-y: #{px-to-rem(4.5)} !important;
  }
  .nav-link.active,
  .show > .nav-link {
    font-weight: 500;
  }
  .nav-link:not(.active) {
    &:hover,
    &:focus {
      border-bottom-color: transparent;
    }
  }
  &.nav-sm {
    $nav-pills-active-border-width: 2px;
    --#{$prefix}nav-underline-border-width: #{$nav-pills-active-border-width};
  }

  // VERTICAL
  &.nav-vertical {
    .nav-link {
      height: $height;
      border-bottom-width: 0;
      --#{$prefix}nav-link-padding-y: #{$padding-y-md} !important;
      // Ensure to use a standard y spacing
      padding-left: map-get($spacers, 3);
      &:not([disabled]):not(.disabled).active {
        background-color: get-interaction-color('selected');
        box-shadow: inset $nav-pills-active-border-width 0 0 0 $nav-link-active-color;
      }
    }

    &.nav-hover {
      .nav-link {
        @include nav-hover();
      }
    }

    // Sizing
    &.nav-sm {
      .nav-link {
        height: $height-sm;
        --#{$prefix}nav-link-padding-y: #{$padding-y-sm} !important;
        padding-left: map-get($spacers, 2);
        &:not([disabled]):not(.disabled).active {
          $nav-pills-active-border-width: 2px;
          box-shadow: inset $nav-pills-active-border-width 0 0 0 $nav-link-active-color;
        }
      }
    }
    &.nav-lg {
      .nav-link {
        height: $height-lg;
        --#{$prefix}nav-link-padding-y: #{$padding-y-lg} !important;
        padding-left: 1.25rem;
      }
    }
    &.nav-xl {
      .nav-link {
        height: $height-xl;
        --#{$prefix}nav-link-padding-y: #{$padding-y-xl} !important;
        padding-left: map-get($spacers, 4);
      }
    }

    // Nested vertical navs
    .nav-underline &.nav-vertical {
      border-left: 1px solid $border-color;
      margin: map-get($spacers, 1) 0 map-get($spacers, 1) map-get($spacers, 3);

      // Sizing
      &.nav-sm {
        margin: map-get($spacers, 1) 0 map-get($spacers, 1) map-get($spacers, 2);
      }
      &.nav-lg {
        margin: map-get($spacers, 1) 0 map-get($spacers, 1) 1.25rem;
      }
      &.nav-xl {
        margin: map-get($spacers, 1) 0 map-get($spacers, 1) map-get($spacers, 4);
      }

      .nav-link.active {
        background-color: transparent !important;
        box-shadow: none !important;
        font-weight: $font-weight-semibold;
      }
    }
  }
}

//
// Nav hover
//

.nav-hover:not(.nav-tabs):not(.nav-vertical) {
  column-gap: unset !important;
  &.nav-sm {
    .nav-link {
      --#{$prefix}nav-link-padding-x: 0.5rem !important;
    }
    &.nav-underline {
      .nav-link {
        padding-right: 0.5rem;
        padding-left: 0.5rem;
      }
    }
  }
  &.nav-lg {
    .nav-link {
      --#{$prefix}nav-link-padding-x: 0.75rem !important;
    }
    &.nav-underline {
      .nav-link {
        padding-right: 0.75rem;
        padding-left: 0.75rem;
      }
    }
  }
  &.nav-xl {
    .nav-link {
      --#{$prefix}nav-link-padding-x: 1rem !important;
    }
    &.nav-underline {
      .nav-link {
        padding-right: 1rem;
        padding-left: 1rem;
      }
    }
  }
  &.nav-underline {
    .nav-link {
      padding-right: 0.625rem;
      padding-left: 0.625rem;
    }
  }
  .nav-link {
    --#{$prefix}nav-link-padding-x: 0.625rem !important;
    @include nav-hover();
  }
}

@import 'bootstrap/scss/forms/labels';

$padding-label-y-md: 6px;
$padding-label-y-lg: 10px;
$padding-label-y-xl: 14px;

// For use with horizontal and inline forms, when you need the label (or legend)
// text to align with the form controls.
.col-form-label {
  padding-top: $padding-label-y-md;
  padding-bottom: $padding-label-y-md;
}

.col-form-label-xl {
  padding-top: $padding-label-y-xl;
  padding-bottom: $padding-label-y-xl;
  font-size: $font-size-base;
}

.col-form-label-lg {
  padding-top: $padding-label-y-lg;
  padding-bottom: $padding-label-y-lg;
}

.col-form-label-sm {
  padding-top: 0;
  padding-bottom: 0;
}
